.search-page {
	.blog-title h1 .fa {
		margin-left: 10px; }




	.searchintrosearch-page {
		display: none; }
	.phrases, .only, .form-limit, .counter {
		display: none; }
	.badge {
		background: $fcolor; }
	.btn-toolbar {
		margin: 0;
		.btn-group {
			margin: 0;
			#search-searchword,
			.btn {
				height: 44px;
				padding: 0 30px;
				margin: 0;
				float: left;
				line-height: 35px;
				vertical-align: top;
				border: 1px solid $scolor;
				border-right: 0;
				&:hover {
					background: $fcolor; } }

			#search-searchword {
				background: #d1d1d1;
				border-radius: 0 5px 5px 0;
				padding: 0 10px;
				color: #000;
				border-left: 0;
				&:hover {
					background: transparent; } } } }

	.searchintro {
		.badge {
			background: $fcolor; } }

	.search-results {
		padding: 20px 0 40px;
		border-top: 1px solid $fcolor;
		.search-result {
			margin-bottom: 15px;
			.result-title {
				margin-bottom: 5px;
				* {
					color: #fff;
					vertical-align: middle; }


				span {
					display: inline-block;
					padding: 2px 5px;
					font-size: 13px;
					line-height: 27px;
					font-weight: bold;
					background: $fcolor;
					border-radius: 0 10px 10px 0; }

				a {
					padding: 2px 10px;
					background: $scolor;
					position: relative;
					margin-right: -4px;

					&.google,
					&.waze {
						padding: 0 2px;
						background: none;
						img {
							width: 27px; } }

					&.google {
						margin-right: 30px; }

					.fa {
						height: 100%;
						padding: 0 2px;
						position: absolute;
						top: 0;
						left: -16px;
						background: $scolor;
						font-size: 14px;
						border-radius: 10px 0 0 10px;
						@include transition-attr(all, .3s);
						&:before {
							line-height: 30px; } }

					&:hover .fa {
						left: -18px; } } }

			.result-text {
				margin: 0 35px 0 0;
				.highlight {
					color: $fcolor;
					font-weight: bold; } } } }

	.pagination {
		width: 100%;
		text-align: center; } }
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.com_search,
.id-162 {
	@media screen and (max-width: 400px) {
		.btn-toolbar {
			display: table-cell;
			.btn-group {
				width: 100%;
				float: none;
				&:nth-child(1) {
					display: table-footer-group; }
				&:nth-child(2) {
					display: table-header-group; }

				input,
				button {
					width: 80%;
					display: block;
					margin: 0 auto;
					float: none;
					border-radius: 0;
					border: 1px solid #a2a2a2; } } } } }

.page-id-101 {

	#slider {
		position: relative;
		z-index: 1;
		height: 450px;
		overflow: hidden;
		.col-xs-12 {
			padding: 0; }

		.slider-1,
		.slider-2 {
			height: 50%;
			overflow: hidden; }
		.slider-1 {
			border: {
				bottom: 2px solid #fff;
				right: 2px solid #fff; } }
		.slider-2 {
			border: {
				top: 2px solid #fff;
				right: 2px solid #fff; } }

		.slider-3 {
			border-left: 2px solid #fff; } }

	#after-slider {
		padding: 30px 0 50px;
		* {
			color: $scolor; }
		.slogan {
			hr {
				border-top: 4px dotted $scolor; }
			p {
				font-size: 26px;
				padding: 0 15px; } }
		.info {
			padding: 25px 0; } } }

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1200px) {
	.page-id-101 #slider {
		height: 350px; } }


@media screen and (max-width: 992px) {
	.page-id-101 #slider {
		height: 250px;
		.container {
			width: 100%; } } }

@media screen and (max-width: 767px) {
	.page-id-101 {
		#slider {
			height: auto;
			.slider-1,
			.slider-2 {
				display: none; }

			.slider-3 img {
				width: 100%; } }

		#after-slider {
			padding: 10px;
			.info {
				padding: 0 15px; }
			* {
				text-align: center; } } } }


.products-listing,
.categories-listing {
	#side-icon {
		display: none; }
	#main-component {
		.hikashop_category_information {
			& > h1 {
				display: none; }
			.before-listing {
				height: 50px;
				border-radius: 30px;
				margin: 30px -12px 10px;
				background: $fcolor;
				overflow: hidden;
				.breadcrumb {
					width: 70%;
					padding: 0;
					float: right;
					background: transparent;
					border-radius: 0;
					margin-bottom: 0;
					a, p, i {
						padding: 0 5px;
						display: inline-block;
						margin-bottom: 0;
						line-height: 50px;
						background: grey;
						float: right;
						&.active {
							color: #fff; }

						&:last-child {
							padding-left: 20px; } }
					i {
						padding-right: 20px; } }
				.slider-pagination {
					width: 30%;
					height: 50px;
					float: right;
					& > * {
						height: 50px;
						position: relative;
						bottom: 0;
						display: inline-block;
						left: 0;
						margin: 0;
						padding: 0;
						float: right;
						line-height: 50px;
						&a {
							width: 15%;
							margin: 0;
							display: block;
							background: $scolor;
							opacity: 1;
							.fa {
								line-height: 50px; }
							&:hover {
								background: grey; } }
						&ol {
							width: 70%;
							background: #fff;
							li {
								height: auto;
								text-indent: 0;
								float: right;
								border: 0;
								margin: 0;
								line-height: 45px;
								border-radius: 0;
								&:hover {
									color: $fcolor; } } } } } }
			.reveal-shipping {
				text-align: center;
				dl {
					margin: 0;
					dt {
						font-size: 26px;
						cursor: pointer;
						padding: 15px; }
					dd {
						margin: 0;
						display: none;
						padding: 15px 60px; } } }
			.hikashop_products,
			.hikashop_subcategories {
				padding-bottom: 50px;
				.hikashop_product,
				.hikashop_category {
					padding: 0;
					.hikashop_container {
						margin: 0;
						.hikashop_subcontainer.thumbnail {
							margin: 0;
							padding: 10px;
							background: transparent;
							border: 0;
							.hikabtn.hikacart {
								width: 100%;
								background: $fcolor;
								border: 0;
								border-radius: 0; }
							.hikashop_category_name,
							.hikashop_product_name {
								display: block;
								background: #fff;
								border: 2px solid $fcolor;
								padding: 5px 0;
								position: relative;
								margin-bottom: 5px;
								a {
									display: block; }
								.fa-star {
									position: absolute;
									top: 10px;
									color: $fcolor;
									&.left {
										left: 10px; }
									&.right {
										right: 10px; } } }

							.hikashop_product_image,
							.hikashop_category_image {
								height: auto;
								background: #fff;
								border: 2px solid $fcolor;
								a,
								.hikashop_product_image_subdiv {
									width: 100%;

									img {
										width: 100%; } }
								a {
									padding: 0; } }

							.hikashop_category_name,
							.hikashop_product_name,
							.hikabtn.hikacart {
								font-size: 26px;
								line-height: 32px; }

							.hikashop_product_quantity_field,
							.hikashop_product_quantity_field_change {
								display: none; } } } } } } } }


.categories-listing #main-component {
	.hikashop_category_information {
		.before-listing {
			display: none; }
		.hikashop_subcategories .hikashop_category .hikashop_container {
			padding-bottom: 20px;
			.hikashop_subcontainer.thumbnail {
				br {
					display: none !important; }
				.hikashop_category_name {
					margin-top: -15%;
					background: $fcolor;
					color: #fff; } } } } }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


@media screen and (max-width: 500px) {
	.products-listing {
		#main-component .hikashop_category_information .before-listing {
			height: auto;
			background: 0;
			border-radius: 0;
			margin: 5px 0;
			.breadcrumb {
				width: 100%;
				background: $fcolor;
				margin-bottom: 5px;
				border-radius: 10px;
				overflow: hidden; }
			.slider-pagination {
				width: 100%;
				background: #fff; } } } }

.products-listing {
	br {
		display: none; }
	form {
		margin: 0; }

	#pitputim-right {
		margin-bottom: 30px;
		padding: 0 5px;
		float: right;
		#hikashop_product_top_part,
		#hikashop_product_right_part {
			border: 2px solid $fcolor;
			background: #fff; }

		#hikashop_product_top_part {
			position: relative;
			margin-bottom: 5px;
			text-align: center;
			h1 {
				line-height: 50px;
				font-size: 24px; }
			.fa {
				position: absolute;
				top: 15px;
				color: $fcolor;
				&.left {
					left: 15px; }
				&.right {
					right: 15px; } } }



		#hikashop_product_right_part {
			width: 100%;
			position: relative;
			padding: 20px 15px;
			#hikashop_product_quantity_main table {
				display: none; }
			#hikashop_product_quantity_main {
				.hikashop_product_quantity_field,
				.hikashop_product_quantity_field_change_plus,
				.hikashop_product_quantity_field_change_minus {
					margin: 0;
					position: absolute;
					height: 54px;
					line-height: 50;
					bottom: -2;
					right: 0;
					z-index: 2;
					background: $scolor;
					color: #fff;
					border: 0;
					.fa {
						line-height: 50px; } }
				.hikashop_product_quantity_field {
					width: 40px;
					right: 36px;
					text-align: center; }

				.hikashop_product_quantity_field_change_plus {
					right: 76px;
					padding: 0 10px; }

				.hikashop_product_quantity_field_change_minus {
					padding: 0 10px; }

				.hikabtn.hikacart {
					width: 100%;
					position: absolute;
					bottom: 0;
					right: 0;
					background: $fcolor;
					line-height: 40px;
					border-radius: 0;
					border: 0;
					font-size: 26px; } }
			#hikashop_product_price_main {
				margin-bottom: 45px;
				float: none; }
			#hikashop_product_custom_info_main,
			#hikashop_product_price_main {
				.product_custom {
					margin-bottom: 15px;
					&_title {
						width: 100%;
						border-bottom: 2px solid $scolor;
						label {
							margin: 0;
							background: $scolor;
							padding: 0 15px;
							color: #fff;
							border-radius: 10px 10px 0 0;
							font-weight: 100; } }
					&_content {
						padding: 5px 15px;
						background: rgba(207, 64, 116, 0.17);
						span {
							float: none;
							display: inline-block; } } } } } }

	#hikashop_product_characteristics {
		margin: 0 -15px;
		.hikashop_variants_table_thead_tr th {
			background: $scolor;
			padding: 3px 5px;
			color: #fff;
			font-weight: 100;
			font-size: 16px;
			text-align: right;

			&.hikashop_product_name {
				display: none; }

			&.hikashop_product_image,
			&.hikashop_product_add_to_cart {
				width: 180px; } }

		.hikashop_variants_table_tbody {
			tr {
				&:nth-child(2n+1) {
					background: #fdd8f3; }

				&:nth-child(2n) {
					background: #f9e5ec; }

				td {
					border-bottom: 1px solid $scolor;
					&.hikashop_product_image_row img {
						max-width: 180px;
						max-height: 180px;
						border: 2px solid $scolor; }
					&.hikashop_product_name_row {
						display: none; }
					&.hikashop_product_add_to_cart_row {
						width: 260px;


						.hikashop_product_quantity_field_change_plus,
						.hikashop_product_quantity_field_change_minus,
						.hikashop_product_quantity_field,
						.hikabtn.hikacart {
							height: 30px;
							padding: 0 10px;
							display: inline-block;
							background: $scolor;
							float: right;
							color: #fff;
							text-align: center; }

						.hikashop_product_quantity_field_change_plus,
						.hikashop_product_quantity_field_change_minus {
							width: 40px;
							@include transition-attr(.3s, all);
							&:hover {
								background: $fcolor; }
							.fa {
								line-height: 33px; } }

						.hikashop_product_quantity_field_change_plus {
							border-radius: 0 10px 10px 0; }

						.hikashop_product_quantity_field {
							width: 45px;
							padding: 0;
							border: 0;
							border: {
								left: 2px solid $fcolor;
								right: 2px solid $fcolor; } }


						.hikabtn.hikacart {
							width: 100px;
							border: 0;
							border-radius: 10px 0 0 10px;
							font-size: 20px;
							background: $fcolor;
							@include transition-attr(.3s, all);
							&:hover {
								background: #ff1469; } } } } } } }




	#pitputim-left {
		padding: 0 5px;
		float: left;
		.hikashop_product_left_part {
			float: none;
			background: #fff;
			border: 2px solid $fcolor;

			#hikashop_product_image_main {
				position: relative;
				#hikashop_main_image_div {
					display: block; }

				#hikashop_image_main_thumb_div {
					width: 100% !important;
					height: auto !important;
					div {
						max-width: 100% !important;
						height: auto !important; }
					#hikashop_main_image {
						margin: 0 !important; } } }

			#hikashop_small_image_div {
				width: 100%;
				position: absolute;
				bottom: 0;
				left: 0; } } }

	.more-product-title {
		height: 50px;
		margin: 10px 0;
		background: $fcolor;
		border-radius: 30px;
		overflow: hidden;
		p {
			width: 250px;
			background: grey;
			margin: 0;
			color: #fff;
			text-align: center;
			i, span {
				line-height: 50px;
				vertical-align: middle; }
			span {
				font-size: 28px; } } }

	#related-items {
		padding-bottom: 30px;
		.container {
			.before-listing {
				display: none; }
			.hikashop_product {
				padding: 0;
				.hikashop_container {
					margin: 0;
					.hikashop_subcontainer.thumbnail {
						margin: 0;
						background: transparent;
						border: 0;
						.hikabtn.hikacart {
							width: 100%;
							background: $scolor;
							border: 0;
							border-radius: 0; }
						.hikashop_product_name {
							background: #fff;
							border: 2px solid $fcolor;
							padding: 5px 0;
							position: relative;
							margin-bottom: 5px;
							a {
								display: block; }
							.fa-star {
								position: absolute;
								top: 10px;
								color: $fcolor;
								&.left {
									left: 10px; }
								&.right {
									right: 10px; } } }

						.hikashop_product_image {
							height: auto;
							background: #fff;
							border: 2px solid $fcolor;
							.hikashop_product_image_subdiv {
								width: 100%;
								padding: 10px;
								img {
									width: 100%; } } }

						.hikashop_product_name,
						.hikabtn.hikacart {
							font-size: 26px;
							line-height: 32px; } } } } } } }

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@media screen and (max-width: 992px) {
	.product-show #related-items .container .hikashop_product {
		width: 50%;
		float: right; } }

@media screen and (max-width: 767px) {
	.product-show #pitputim-right #hikashop_product_right_part #hikashop_product_description_main {
		height: auto;
		text-align: justify; } }



@media screen and (max-width: 500px) {
	.product-show  #related-items .container .hikashop_product {
		width: 100%; } }

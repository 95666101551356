.gallery-page {
	#side-icon {
		display: none; }

	#main-component {
		.uk-panel-teaser,
		.uk-flex-wrap.uk-clearfix.uk-margin {
			margin: 0; }

		.uk-flex-wrap.uk-clearfix.uk-margin * {
			width: 100%;
			padding: 0;
			a {
				background: $fcolor;
				color: #fff; } } } }


.gallery-page {
	#main-component .uk-subnav.uk-flex-center {
		overflow: hidden;
		li {
			background: $scolor;
			padding: 5px 20px;
			border-left: 2px solid $fcolor;
			a {
				color: #fff !important; }
			&.uk-active {
				background: $fcolor; }

			&:first-child {
				border-radius: 0 10px 10px 0; }
			&:last-child {
				border-radius: 10px 0 0 10px;
				border-left: 0; } } }


	.uk-overlay-hover:hover .uk-overlay-fade {
		opacity: 0.3 !important; }

	.uk-modal.uk-open {
		.uk-slidenav-position .uk-slidenav {
			background: #CF4074;
			color: #fff;
			&.uk-slidenav-previous {
				left: 0;
				border-radius: 0 50% 50% 0; }
			&.uk-slidenav-next {
				right: 0;
				border-radius: 50% 0 0 50%; } } } }

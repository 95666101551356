@charset "UTF-8";
@font-face {
  font-family: 'Open Sans Hebrew';
  src: url("../fonts/opensanshebrew-regular-webfont.eot"), url("../fonts/opensanshebrew-regular-webfont.woff") format("woff"), url("../fonts/opensanshebrew-regular-webfont.ttf") format("truetype"), url("../fonts/opensanshebrew-regular-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

body,
html {
  overflow-x: hidden; }

body {
  direction: rtl;
  min-height: 100vh; }
  body * {
    direction: rtl; }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }
  ul li {
    display: inline-block; }

img {
  max-width: 100%; }

a, p span, div, h1, h2, h3, h4, td, th, li {
  font-family: "Open Sans Hebrew";
  font-size: 20px;
  margin: 0; }

a {
  color: inherit;
  text-decoration: none; }
  a:hover {
    color: inherit;
    text-decoration: none; }

.container {
  position: relative; }

.mobile {
  display: none !important; }

div[class^="col"] {
  float: right; }

.alert {
  background: #d2396b; }
  .alert a, .alert h4, .alert div {
    color: #fff; }
  .alert a.close {
    float: left;
    opacity: 1; }

#cart {
  width: 100%;
  position: fixed;
  top: 245px;
  left: 0;
  padding: 0;
  background: #3e2d26;
  z-index: 3;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out; }
  #cart.show {
    padding: 20px 0;
    max-height: 2000px;
    overflow: visible; }
  #cart * {
    color: #fff; }
  #cart #hikashop_cart_module {
    width: 500px;
    margin: 0 auto; }
    #cart #hikashop_cart_module .hikashop_cart {
      text-align: center; }
    #cart #hikashop_cart_module table.hikashop_cart {
      margin-bottom: 15px; }
      #cart #hikashop_cart_module table.hikashop_cart * {
        text-align: right;
        font-size: 16px; }
      #cart #hikashop_cart_module table.hikashop_cart thead tr {
        background: rgba(207, 64, 116, 0.38); }
        #cart #hikashop_cart_module table.hikashop_cart thead tr th {
          padding: 3px 5px; }
      #cart #hikashop_cart_module table.hikashop_cart tbody tr {
        border-bottom: 1px solid #fff; }
        #cart #hikashop_cart_module table.hikashop_cart tbody tr:nth-child(2n) {
          background: rgba(227, 227, 227, 0.28); }
        #cart #hikashop_cart_module table.hikashop_cart tbody tr:nth-child(2n+1) {
          background: rgba(227, 227, 227, 0.5); }
        #cart #hikashop_cart_module table.hikashop_cart tbody tr td {
          padding: 2px 5px; }
          #cart #hikashop_cart_module table.hikashop_cart tbody tr td input {
            background: transparent;
            border: 0;
            text-align: center; }
      #cart #hikashop_cart_module table.hikashop_cart tfoot tr td {
        padding: 3px 5px; }
        #cart #hikashop_cart_module table.hikashop_cart tfoot tr td.hikashop_cart_module_product_total_title {
          background: #CF4074;
          border-radius: 0 0 5px 0; }
        #cart #hikashop_cart_module table.hikashop_cart tfoot tr td.hikashop_cart_module_product_total_value {
          background: #CF4074;
          border-radius: 0 0 0 5px; }
  #cart .btns a {
    background: none;
    float: left;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    #cart .btns a span, #cart .btns a .fa {
      padding: 3px 10px;
      display: inline-block;
      vertical-align: top;
      line-height: 30px;
      float: right;
      background: rgba(207, 63, 116, 0.4); }
    #cart .btns a#to-shop {
      margin-left: 17px;
      float: left;
      border-radius: 5px 0 0 5px; }
    #cart .btns a.pay {
      margin-left: 17px;
      float: left;
      border-radius: 5px; }
    #cart .btns a:hover {
      background: #CF4074; }

#cart.sticky {
  top: 145px; }

#top {
  width: 100%;
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  background: #CF4074; }
  #top .top-menu .row {
    position: relative; }
    #top .top-menu .row a, #top .top-menu .row p {
      margin: 0;
      display: inline-block;
      line-height: 55px;
      color: #fff; }
      #top .top-menu .row a.space, #top .top-menu .row p.space {
        margin: 0 5px; }
    #top .top-menu .row .search-form {
      position: relative;
      z-index: 2; }
      #top .top-menu .row .search-form form {
        width: 100%;
        position: absolute;
        top: 20px;
        right: 0;
        background: #CF4074;
        border: 5px solid #CF4074;
        border-radius: 0 0 0 15px; }
        #top .top-menu .row .search-form form .fa {
          position: absolute;
          right: 5px;
          top: 7px;
          font-size: 26px;
          color: grey;
          cursor: pointer; }
        #top .top-menu .row .search-form form .search-query {
          width: 100%;
          height: 40px;
          border: 0;
          border-radius: 10px;
          padding: 0 45px 0 10px; }
    #top .top-menu .row .login {
      text-align: left; }
      #top .top-menu .row .login * {
        display: inline; }
      #top .top-menu .row .login .space {
        margin: 0; }
      #top .top-menu .row .login a .fa {
        margin-right: 10px; }
    #top .top-menu .row .phone {
      text-align: center; }
      #top .top-menu .row .phone * {
        display: inline-block; }
      #top .top-menu .row .phone p {
        font-size: 24px; }
      #top .top-menu .row .phone .fa-cont {
        width: 35px;
        height: 35px;
        margin-right: 6px;
        border-radius: 50%;
        border: 1px solid #fff;
        text-align: center; }
        #top .top-menu .row .phone .fa-cont .fa {
          line-height: 35px;
          font-size: 20px; }
    #top .top-menu .row .icons {
      padding: 0;
      position: absolute;
      left: 0;
      bottom: -35px;
      z-index: 2;
      text-align: left; }
      #top .top-menu .row .icons a {
        width: 50px;
        height: 50px;
        display: inline-block;
        background: #CF4074;
        border-radius: 50%;
        border: 3px solid #fff;
        text-align: center;
        position: relative;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
        #top .top-menu .row .icons a .tooltip {
          position: absolute;
          top: -40px;
          left: 30%;
          padding: 5px;
          line-height: 20px;
          background: #3e2d26;
          border-radius: 5px;
          white-space: nowrap;
          opacity: 0;
          max-height: 0;
          overflow: hidden;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out; }
          #top .top-menu .row .icons a .tooltip * {
            font-size: 16px; }
          #top .top-menu .row .icons a .tooltip .fa {
            position: absolute;
            line-height: 10px;
            bottom: -6px;
            left: 5px;
            color: #3e2d26; }
        #top .top-menu .row .icons a .fa {
          line-height: 40px;
          font-size: 23px; }
        #top .top-menu .row .icons a:hover {
          background: #3e2d26; }
          #top .top-menu .row .icons a:hover .tooltip {
            opacity: 1;
            max-height: 100px;
            overflow: visible; }
      #top .top-menu .row .icons a.search {
        display: none; }
      #top .top-menu .row .icons a.cart {
        position: relative; }
        #top .top-menu .row .icons a.cart .cart-counter {
          width: 30px;
          height: 30px;
          position: absolute;
          bottom: -15px;
          left: -15px;
          background: #3e2d26;
          text-align: center;
          line-height: 30px;
          font-size: 13px;
          font-weight: bold;
          border-radius: 50%;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out; }
        #top .top-menu .row .icons a.cart.toggled {
          background: #3e2d26; }
          #top .top-menu .row .icons a.cart.toggled:hover {
            background: #3e2d26; }
          #top .top-menu .row .icons a.cart.toggled .cart-counter {
            background: #CF4074; }
  #top .logo {
    background: #fff;
    text-align: center; }
    #top .logo .col-sm-4 {
      margin-right: 33.33333333%; }
      #top .logo .col-sm-4 a {
        display: block; }
        #top .logo .col-sm-4 a img {
          max-width: 350px;
          margin-top: -45px;
          -webkit-transition: 0.5s all ease-in-out;
          -moz-transition: 0.5s all ease-in-out;
          transition: 0.5s all ease-in-out; }

#top.sticky .logo a img {
  max-width: 140px;
  margin-top: -45px; }

header {
  width: 100%;
  padding: 20px 0;
  position: fixed;
  top: 170px;
  left: 0px;
  z-index: 6;
  background: #fff;
  -webkit-transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out; }
  header .mobile-menu {
    display: none; }
    header .mobile-menu .fa {
      padding: 15px;
      cursor: pointer;
      font-size: 34px;
      background: #3e2d26;
      color: #fff; }
  header nav .nav.menu li {
    display: inline-block;
    padding: 4px 20px;
    position: relative;
    float: right; }
    header nav .nav.menu li a {
      display: inline-block;
      padding: 0;
      font-size: 18px;
      color: #3e2d26; }
    header nav .nav.menu li:hover a {
      color: #CF4074;
      background: inherit; }
    header nav .nav.menu li.current {
      border-bottom: 2px solid #CF4074; }
    header nav .nav.menu li.parent ul {
      width: 200px;
      max-height: 0;
      position: absolute;
      top: 30px;
      right: 0;
      overflow: hidden;
      background: #fff2f2;
      -webkit-transition: all ease-in-out 1s;
      -moz-transition: all ease-in-out 1s;
      -ms-transition: all ease-in-out 1s;
      -o-transition: all ease-in-out 1s;
      transition: all ease-in-out 1s;
      z-index: 5; }
      header nav .nav.menu li.parent ul li {
        width: 100%;
        display: block;
        background: #3e2d26; }
        header nav .nav.menu li.parent ul li a {
          color: #fff; }
        header nav .nav.menu li.parent ul li:hover {
          background: #CF4074; }
        header nav .nav.menu li.parent ul li:after {
          display: none; }
    header nav .nav.menu li.parent:hover ul {
      max-height: 1000px; }
    header nav .nav.menu li:not(:last-child):after {
      content: '■';
      width: 6px;
      height: 6px;
      position: absolute;
      left: -4px;
      top: 15px;
      font-size: 12px;
      line-height: 6px; }

header.sticky {
  top: 75px;
  border-bottom: 2px solid #CF4074; }

header + section {
  margin-top: 260px; }

#main-component {
  direction: rtl;
  position: relative; }
  #main-component .bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.1;
    background: url(../images/imageBG.jpg) no-repeat; }
  #main-component aside {
    background: url(../images/formBG.png); }

#map {
  position: relative;
  z-index: 1; }

footer {
  position: relative;
  z-index: 1;
  padding: 30px 60px 10px;
  direction: rtl;
  background: #3e2d26;
  border-top: 5px solid #fad3dd; }
  footer * {
    color: #fff; }
  footer .row {
    padding: 20px 0; }
    footer .row > div {
      float: right; }
      footer .row > div.menu ul {
        width: 33%;
        float: right;
        display: inline-block; }
        footer .row > div.menu ul li {
          width: 100%; }
          footer .row > div.menu ul li a {
            line-height: 30px; }
      footer .row > div.adress {
        border-top: 4px dotted #fff;
        border-bottom: 4px dotted #fff; }
      footer .row > div.maillist > p {
        padding: 0 10px;
        margin-top: -25px;
        border-bottom: 2px solid #CF4074; }
        footer .row > div.maillist > p .fa {
          font-size: 18px;
          margin-left: 5px; }
      footer .row > div.maillist form {
        margin: 0; }
      footer .row > div.maillist .left,
      footer .row > div.maillist .right {
        height: 122px; }
      footer .row > div.maillist .left {
        width: 65%;
        float: left; }
        footer .row > div.maillist .left input {
          width: 100% !important;
          color: #3e2d26;
          padding: 1px 5px 1px 0; }
      footer .row > div.maillist .right {
        width: 35%;
        float: right; }
        footer .row > div.maillist .right input {
          height: 100%;
          width: 90%;
          background: grey;
          color: #fff;
          border: 0;
          float: right; }
          footer .row > div.maillist .right input:hover {
            background: #CF4074; }

#side-icon {
  position: fixed;
  right: 5px;
  z-index: 3;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }
  #side-icon a {
    display: block; }

.uk-modal-dialog.uk-modal-dialog-lightbox.uk-slidenav-position {
  position: relative; }
  .uk-modal-dialog.uk-modal-dialog-lightbox.uk-slidenav-position .uk-modal-close.uk-close.uk-close-alt {
    position: absolute;
    left: 0;
    top: 0;
    color: #CF4074;
    background: #fff;
    border-radius: 0 0 10px; }
    .uk-modal-dialog.uk-modal-dialog-lightbox.uk-slidenav-position .uk-modal-close.uk-close.uk-close-alt:after {
      opacity: 1; }

.blog-title {
  width: 100%;
  height: 50px;
  background: #c84776;
  margin-bottom: 20px;
  border-radius: 35px;
  overflow: hidden;
  margin: 30px 0; }
  .blog-title h1 {
    width: 300px;
    padding-right: 30px;
    background: grey;
    line-height: 50px;
    font-size: 26px;
    color: #fff; }

.notifyjs-corner {
  margin: 0; }
  .notifyjs-corner .notifyjs-metro-base.notifyjs-metro-info {
    background: #CF4074;
    border: 2px solid #3e2d26; }
    .notifyjs-corner .notifyjs-metro-base.notifyjs-metro-info * {
      color: #fff;
      text-align: right; }
    .notifyjs-corner .notifyjs-metro-base.notifyjs-metro-info .image {
      right: 0;
      left: auto;
      text-align: right; }
    .notifyjs-corner .notifyjs-metro-base.notifyjs-metro-info .text-wrapper {
      margin: 10px 100px 10px 10px; }

@media screen and (max-width: 1500px) {
  footer {
    padding: 30px 0 10px; }
    footer .row {
      text-align: center;
      padding: 0; }
      footer .row > div {
        width: 100%;
        max-width: 800px;
        display: inline-block;
        float: none;
        clear: both;
        margin: 0 auto;
        text-align: center;
        padding: 12px 0; }
        footer .row > div.maillist > p {
          margin: 10px 0 0; } }

@media screen and (max-width: 1350px) {
  #top {
    padding-top: 55px; }
    #top .phone-icons {
      width: 83%;
      height: 50px; }
      #top .phone-icons .icons {
        width: 100%;
        text-align: left; }
        #top .phone-icons .icons a {
          margin: 0 !important; }
    #top .top-menu .row .search-form form {
      top: -35px; }
    #top .phone,
    #top .login {
      display: none; }
    #top .mobile {
      display: inline-block !important; } }

@media screen and (max-width: 1190px) {
  header {
    padding: 0;
    background: #3e2d26;
    z-index: 10 !important; }
    header .container {
      width: 100%; }
      header .container nav {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        right: -300%;
        background: #fff;
        padding: 20px 0;
        z-index: 99;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
        header .container nav .nav.menu {
          display: block;
          width: 100%; }
          header .container nav .nav.menu li {
            width: 100%;
            display: block;
            padding: 7px 20px;
            background: #3e2d26;
            border-bottom: 2px solid #fff; }
            header .container nav .nav.menu li a {
              display: block;
              color: #fff; }
            header .container nav .nav.menu li.current {
              background: #CF4074; }
            header .container nav .nav.menu li:after {
              display: none; }
        header .container nav .mobile.close {
          width: 100%;
          display: block !important;
          background: #3e2d26;
          opacity: 1;
          text-align: center;
          border-radius: 0 0 20px 20px; }
          header .container nav .mobile.close .fa {
            font-size: 40px;
            margin-bottom: -30px;
            background: #3e2d26;
            border-radius: 50%;
            color: #fff;
            text-shadow: none;
            border: 6px solid #3e2d26; }
      header .container nav.show {
        right: 0; }
      header .container .mobile-menu {
        display: block; }
  #top.sticky .top-menu .row .icons {
    bottom: 2px; } }

@media screen and (max-width: 992px) {
  #top .top-menu .row .search-form {
    display: none; }
  #top .top-menu .row .phone-icons {
    width: 100%;
    float: left; }
    #top .top-menu .row .phone-icons .icons {
      width: 100%;
      bottom: -25px; }
      #top .top-menu .row .phone-icons .icons a {
        width: 50px;
        height: 50px;
        border: 2px solid #fff; }
        #top .top-menu .row .phone-icons .icons a .fa {
          line-height: 45px;
          font-size: 26px; }
      #top .top-menu .row .phone-icons .icons a.search {
        display: inline-block; }
  #top .top-menu .row .icons {
    bottom: 3px; }
  #top .logo .col-sm-4 {
    margin: 0; }
    #top .logo .col-sm-4 a img {
      margin: 0;
      max-width: 200px; }
  header,
  header.sticky {
    top: 145px; }
  header + section {
    margin-top: 215px; } }

@media screen and (max-width: 767px) {
  #side-icon {
    display: none; }
  #top .top-menu .row .phone-icons {
    height: 60px;
    padding: 5px 0; }
    #top .top-menu .row .phone-icons .icons {
      position: relative;
      bottom: 0; }
  footer .row > div.menu ul li a {
    font-size: 15px;
    display: block; }
  footer .row > div.adress * {
    font-size: 16px; }
  footer .row > div.maillist {
    padding: 5px; }
    footer .row > div.maillist .left,
    footer .row > div.maillist .right {
      width: 100%;
      max-width: 500px;
      height: auto;
      margin: 0 auto !important;
      float: none; }
    footer .row > div.maillist .right {
      margin-top: 5px; }
      footer .row > div.maillist .right input {
        height: 45px;
        width: 100%;
        background: #CF4074; } }

@media screen and (max-width: 550px) {
  #cart {
    top: 210px; }
    #cart.sticky {
      top: 110px; }
    #cart #hikashop_cart_module {
      width: 100%; }
  #top .top-menu .row .icons {
    width: 100%;
    bottom: 8px;
    text-align: center; }
    #top .top-menu .row .icons a {
      width: 40px;
      height: 40px; }
      #top .top-menu .row .icons a .fa {
        line-height: 33px;
        font-size: 19px; }
  #top.sticky {
    padding-top: 0; }
    #top.sticky .logo .col-sm-4 a img {
      max-width: 140px; }
    #top.sticky .top-menu .row .icons {
      display: none; }
  header.sticky {
    top: 65px; }
    header.sticky .mobile-menu .fa {
      padding: 5px; } }

.page-id-101 #slider {
  position: relative;
  z-index: 1;
  height: 450px;
  overflow: hidden; }
  .page-id-101 #slider .col-xs-12 {
    padding: 0; }
  .page-id-101 #slider .slider-1,
  .page-id-101 #slider .slider-2 {
    height: 50%;
    overflow: hidden; }
  .page-id-101 #slider .slider-1 {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff; }
  .page-id-101 #slider .slider-2 {
    border-top: 2px solid #fff;
    border-right: 2px solid #fff; }
  .page-id-101 #slider .slider-3 {
    border-left: 2px solid #fff; }

.page-id-101 #after-slider {
  padding: 30px 0 50px; }
  .page-id-101 #after-slider * {
    color: #3e2d26; }
  .page-id-101 #after-slider .slogan hr {
    border-top: 4px dotted #3e2d26; }
  .page-id-101 #after-slider .slogan p {
    font-size: 26px;
    padding: 0 15px; }
  .page-id-101 #after-slider .info {
    padding: 25px 0; }

@media screen and (max-width: 1200px) {
  .page-id-101 #slider {
    height: 350px; } }

@media screen and (max-width: 992px) {
  .page-id-101 #slider {
    height: 250px; }
    .page-id-101 #slider .container {
      width: 100%; } }

@media screen and (max-width: 767px) {
  .page-id-101 #slider {
    height: auto; }
    .page-id-101 #slider .slider-1,
    .page-id-101 #slider .slider-2 {
      display: none; }
    .page-id-101 #slider .slider-3 img {
      width: 100%; }
  .page-id-101 #after-slider {
    padding: 10px; }
    .page-id-101 #after-slider .info {
      padding: 0 15px; }
    .page-id-101 #after-slider * {
      text-align: center; } }

.inner-page #main-component {
  padding: 10px 0 100px; }
  .inner-page #main-component .container > div {
    float: right; }
    .inner-page #main-component .container > div h1 {
      margin-top: 0; }
    .inner-page #main-component .container > div a {
      color: #CF4074; }
    .inner-page #main-component .container > div p, .inner-page #main-component .container > div li {
      margin-bottom: 10px; }
    .inner-page #main-component .container > div ul li {
      display: block; }
      .inner-page #main-component .container > div ul li p, .inner-page #main-component .container > div ul li:before {
        display: inline; }
      .inner-page #main-component .container > div ul li:before {
        content: '\f100';
        font-size: 18px;
        font-family: FontAwesome; }
  .inner-page #main-component .container aside h2 {
    font-size: 24px; }

.inner-page.branches-page #main-component .container {
  background: rgba(255, 255, 255, 0.44); }

.inner-page.branches-page #main-component .branches {
  width: 100%;
  margin-bottom: 50px; }
  .inner-page.branches-page #main-component .branches .branch-title {
    margin-bottom: 15px;
    border-bottom: 2px solid #3e2d26; }
    .inner-page.branches-page #main-component .branches .branch-title h2 {
      background: #3e2d26;
      display: inline-block;
      padding: 5px 15px;
      color: #fff;
      border-radius: 10px 10px 0 0; }
  .inner-page.branches-page #main-component .branches .branch-container {
    height: 45px; }
    .inner-page.branches-page #main-component .branches .branch-container img {
      max-width: 40px;
      vertical-align: middle; }

.inner-page.branches-page #main-component .bottom-part {
  width: 100%;
  padding: 20px 0;
  margin-top: 30px;
  text-align: center;
  border: 1px solid #CF4074; }

.inner-page.contactus-page #side-icon {
  display: none; }

.inner-page.contactus-page #map {
  height: 80vh; }

.inner-page.contactus-page #main-component {
  width: 100%;
  padding: 0;
  z-index: 2; }
  .inner-page.contactus-page #main-component #myCarousel {
    display: none !important; }
  .inner-page.contactus-page #main-component .bg {
    display: none; }
  .inner-page.contactus-page #main-component .container {
    width: 100%;
    position: relative; }
    .inner-page.contactus-page #main-component .container .col-sm-4 {
      width: 420px;
      position: absolute;
      top: 80px;
      padding: 30px;
      background: #3e2d26;
      border-radius: 30px; }
      .inner-page.contactus-page #main-component .container .col-sm-4.left {
        left: 30px; }
      .inner-page.contactus-page #main-component .container .col-sm-4.right {
        right: 30px;
        top: 6px; }
        .inner-page.contactus-page #main-component .container .col-sm-4.right #contactForm {
          margin: 0; }
          .inner-page.contactus-page #main-component .container .col-sm-4.right #contactForm li {
            width: 100%;
            margin-bottom: 10px;
            position: relative;
            -ms-input-placeholder-color: #fff;
            -moz-placeholder-color: #fff; }
            .inner-page.contactus-page #main-component .container .col-sm-4.right #contactForm li > * {
              float: right; }
            .inner-page.contactus-page #main-component .container .col-sm-4.right #contactForm li input, .inner-page.contactus-page #main-component .container .col-sm-4.right #contactForm li textarea, .inner-page.contactus-page #main-component .container .col-sm-4.right #contactForm li select {
              width: 100%;
              border-radius: 3px;
              border: 0;
              line-height: 30px;
              background: #CF4074;
              padding: 5px 10px; }
            .inner-page.contactus-page #main-component .container .col-sm-4.right #contactForm li textarea {
              height: 100px; }
            .inner-page.contactus-page #main-component .container .col-sm-4.right #contactForm li ::-webkit-input-placeholder {
              color: #fff; }
            .inner-page.contactus-page #main-component .container .col-sm-4.right #contactForm li ::-moz-placeholder {
              color: #fff; }
            .inner-page.contactus-page #main-component .container .col-sm-4.right #contactForm li .button {
              float: left;
              background: grey;
              color: #fff;
              font-size: 20px;
              padding: 5px; }
            .inner-page.contactus-page #main-component .container .col-sm-4.right #contactForm li .plk {
              height: 40px;
              position: absolute;
              top: 0;
              left: 15px;
              border-left: 4px solid #3e2d26; }
        .inner-page.contactus-page #main-component .container .col-sm-4.right .fox-form {
          margin: 0; }
          .inner-page.contactus-page #main-component .container .col-sm-4.right .fox-form .fox-item {
            float: none;
            position: relative; }
            .inner-page.contactus-page #main-component .container .col-sm-4.right .fox-form .fox-item .controls {
              width: 100%;
              margin-bottom: 10px;
              -ms-input-placeholder-color: #fff;
              -moz-placeholder-color: #fff; }
              .inner-page.contactus-page #main-component .container .col-sm-4.right .fox-form .fox-item .controls input, .inner-page.contactus-page #main-component .container .col-sm-4.right .fox-form .fox-item .controls textarea, .inner-page.contactus-page #main-component .container .col-sm-4.right .fox-form .fox-item .controls select {
                width: 100%;
                border-radius: 3px;
                border: 0;
                line-height: 30px;
                background: #CF4074;
                padding: 5px 10px;
                min-height: 40px; }
              .inner-page.contactus-page #main-component .container .col-sm-4.right .fox-form .fox-item .controls textarea {
                height: 100px; }
              .inner-page.contactus-page #main-component .container .col-sm-4.right .fox-form .fox-item .controls ::-webkit-input-placeholder {
                color: #fff; }
              .inner-page.contactus-page #main-component .container .col-sm-4.right .fox-form .fox-item .controls ::-moz-placeholder {
                color: #fff; }
              .inner-page.contactus-page #main-component .container .col-sm-4.right .fox-form .fox-item .controls .btn {
                float: left;
                background: grey;
                color: #fff;
                font-size: 20px;
                padding: 5px;
                margin: 0;
                min-height: 40px;
                width: 100%;
                background-image: none;
                box-shadow: unset;
                border: 0;
                text-shadow: unset; }
              .inner-page.contactus-page #main-component .container .col-sm-4.right .fox-form .fox-item .controls .plk {
                height: 40px;
                position: absolute;
                top: 0;
                left: 15px;
                border-left: 4px solid #3e2d26; }
            .inner-page.contactus-page #main-component .container .col-sm-4.right .fox-form .fox-item.fox-item-radio .controls > div {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              gap: 10px; }
              .inner-page.contactus-page #main-component .container .col-sm-4.right .fox-form .fox-item.fox-item-radio .controls > div .fox-label-inside-no-placeholder {
                display: flex;
                width: 100%;
                flex: 100%;
                font-weight: 700;
                text-decoration: underline;
                margin-bottom: 7px;
                font-size: 17px; }
              .inner-page.contactus-page #main-component .container .col-sm-4.right .fox-form .fox-item.fox-item-radio .controls > div .fox-item-radio-label-stacked {
                max-width: 100%;
                width: 100%;
                text-align: center; }
                .inner-page.contactus-page #main-component .container .col-sm-4.right .fox-form .fox-item.fox-item-radio .controls > div .fox-item-radio-label-stacked label {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  font-size: 15px; }
                  .inner-page.contactus-page #main-component .container .col-sm-4.right .fox-form .fox-item.fox-item-radio .controls > div .fox-item-radio-label-stacked label input {
                    max-width: 40px; }
              .inner-page.contactus-page #main-component .container .col-sm-4.right .fox-form .fox-item.fox-item-radio .controls > div input {
                min-height: 10px;
                margin: 0; }
      .inner-page.contactus-page #main-component .container .col-sm-4 * {
        color: #fff; }
      .inner-page.contactus-page #main-component .container .col-sm-4 h2 {
        color: #CF4074;
        font-size: 26px;
        margin-bottom: 15px; }
      .inner-page.contactus-page #main-component .container .col-sm-4 hr {
        border-top: 1px solid #73594f;
        margin: 5px 0; }

.inner-page.guest-book #main-component .container > div {
  float: none; }
  .inner-page.guest-book #main-component .container > div .grid .grid-item {
    float: right;
    padding: 10px; }
    .inner-page.guest-book #main-component .container > div .grid .grid-item .inner-container {
      border: 1px solid #CF4074;
      padding: 20px 20px 0;
      background: rgba(255, 255, 255, 0.5); }
      .inner-page.guest-book #main-component .container > div .grid .grid-item .inner-container h2 {
        text-align: center;
        color: #CF4074;
        margin: 0 0 20px 0; }
      .inner-page.guest-book #main-component .container > div .grid .grid-item .inner-container p {
        text-align: justify; }
      .inner-page.guest-book #main-component .container > div .grid .grid-item .inner-container a {
        text-align: center;
        display: block; }
    .inner-page.guest-book #main-component .container > div .grid .grid-item .item-image {
      border: 1px solid #CF4074;
      border-top: 0; }
    .inner-page.guest-book #main-component .container > div .grid .grid-item.guest-book .inner-container * {
      text-align: center; }
    .inner-page.guest-book #main-component .container > div .grid .grid-item.guest-book h2 {
      display: none; }

.inner-page.blog #main-component .container .blog .hr, .inner-page.wrote-aboutus-page #main-component .container .blog .hr {
  border-top: 6px dotted #c84776;
  margin: 10px 0;
  clear: both;
  float: none; }

.inner-page.blog #main-component .container .blog .grid-item, .inner-page.wrote-aboutus-page #main-component .container .blog .grid-item {
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid #CF4074;
  background: #fff; }
  .inner-page.blog #main-component .container .blog .grid-item .inner-container,
  .inner-page.blog #main-component .container .blog .grid-item .item-image, .inner-page.wrote-aboutus-page #main-component .container .blog .grid-item .inner-container,
  .inner-page.wrote-aboutus-page #main-component .container .blog .grid-item .item-image {
    float: left; }
  .inner-page.blog #main-component .container .blog .grid-item h2, .inner-page.wrote-aboutus-page #main-component .container .blog .grid-item h2 {
    width: 70%;
    margin: 0 0 20px 0;
    padding-left: 15px;
    text-align: right;
    color: #CF4074;
    font-size: 28px;
    position: relative; }
    .inner-page.blog #main-component .container .blog .grid-item h2 .created-date, .inner-page.wrote-aboutus-page #main-component .container .blog .grid-item h2 .created-date {
      padding: 5px 10px;
      background: grey;
      float: left;
      color: #fff; }
  .inner-page.blog #main-component .container .blog .grid-item .item-image, .inner-page.wrote-aboutus-page #main-component .container .blog .grid-item .item-image {
    width: 30%; }
    .inner-page.blog #main-component .container .blog .grid-item .item-image a, .inner-page.wrote-aboutus-page #main-component .container .blog .grid-item .item-image a {
      display: block;
      margin-top: -50px; }
  .inner-page.blog #main-component .container .blog .grid-item .inner-container, .inner-page.wrote-aboutus-page #main-component .container .blog .grid-item .inner-container {
    width: 70%;
    padding-left: 15px;
    position: relative;
    text-align: justify; }
    .inner-page.blog #main-component .container .blog .grid-item .inner-container a, .inner-page.wrote-aboutus-page #main-component .container .blog .grid-item .inner-container a {
      padding: 10px 25px;
      background: #CF4074;
      color: #fff;
      float: left;
      margin-top: 20px; }

.inner-page.blog #main-component .item-pageblog .pull-left.item-image {
  max-width: 35%;
  margin-right: 30px; }

.search-page #main-component {
  min-height: 50vh;
  padding: 30px 0; }
  .search-page #main-component #searchForm .searchintrosearch-page .badge {
    background: #CF4074; }
  .search-page #main-component #searchForm .btn-toolbar .btn-group {
    margin: 0; }
    .search-page #main-component #searchForm .btn-toolbar .btn-group .btn {
      background: #3e2d26;
      color: #fff;
      line-height: 30px;
      border-radius: 10px 0 0 10px; }
      .search-page #main-component #searchForm .btn-toolbar .btn-group .btn:hover {
        background: #CF4074; }
    .search-page #main-component #searchForm .btn-toolbar .btn-group #search-searchword {
      height: 44px;
      padding-right: 10px;
      border: 1px solid #3e2d26;
      border-radius: 0 10px 10px 0; }
  .search-page #main-component .search-results .result-text {
    margin-bottom: 20px; }

@media screen and (max-width: 1400px) {
  .inner-page.contactus-page #main-component {
    background: #3e2d26; }
    .inner-page.contactus-page #main-component .container .left,
    .inner-page.contactus-page #main-component .container .right {
      width: 50%;
      position: relative;
      top: 0 !important;
      left: auto !important;
      right: auto !important; } }

@media screen and (max-width: 1200px) {
  .inner-page.wrote-aboutus-page #main-component .container .blog .grid-item h2 {
    width: 100%;
    padding: 0; }
  .inner-page.wrote-aboutus-page #main-component .container .blog .grid-item .item-image a {
    margin-top: 0; } }

@media screen and (max-width: 850px) {
  .inner-page.contactus-page #main-component {
    margin-top: 20px;
    background: #3e2d26; }
    .inner-page.contactus-page #main-component .container .left,
    .inner-page.contactus-page #main-component .container .right {
      width: 100%;
      padding: 10px 30px; }
  .inner-page.contactus-page #map {
    height: 280px; }
  .inner-page.branches-page #main-component * {
    font-size: 16px; } }

@media screen and (max-width: 767px) {
  .inner-page.wrote-aboutus-page #main-component .container .blog .grid-item .item-image,
  .inner-page.wrote-aboutus-page #main-component .container .blog .grid-item .inner-container {
    width: 100%; }
  .inner-page.wrote-aboutus-page #main-component .container .blog .grid-item h2 {
    padding: 0 0 35px 0;
    text-align: center; }
    .inner-page.wrote-aboutus-page #main-component .container .blog .grid-item h2 .created-date {
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 16px; }
  .inner-page.wrote-aboutus-page #main-component .container .blog .grid-item .inner-container * {
    text-align: justify; }
  .inner-page.wrote-aboutus-page #main-component .container .blog .grid-item .item-image {
    text-align: center; }
    .inner-page.wrote-aboutus-page #main-component .container .blog .grid-item .item-image img {
      width: 300px; } }

@media screen and (max-width: 500px) {
  .inner-page.contactus-page #main-component .container .left,
  .inner-page.contactus-page #main-component .container .right {
    padding: 10px 0; } }

.search-page .blog-title h1 .fa {
  margin-left: 10px; }

.search-page .searchintrosearch-page {
  display: none; }

.search-page .phrases, .search-page .only, .search-page .form-limit, .search-page .counter {
  display: none; }

.search-page .badge {
  background: #CF4074; }

.search-page .btn-toolbar {
  margin: 0; }
  .search-page .btn-toolbar .btn-group {
    margin: 0; }
    .search-page .btn-toolbar .btn-group #search-searchword,
    .search-page .btn-toolbar .btn-group .btn {
      height: 44px;
      padding: 0 30px;
      margin: 0;
      float: left;
      line-height: 35px;
      vertical-align: top;
      border: 1px solid #3e2d26;
      border-right: 0; }
      .search-page .btn-toolbar .btn-group #search-searchword:hover,
      .search-page .btn-toolbar .btn-group .btn:hover {
        background: #CF4074; }
    .search-page .btn-toolbar .btn-group #search-searchword {
      background: #d1d1d1;
      border-radius: 0 5px 5px 0;
      padding: 0 10px;
      color: #000;
      border-left: 0; }
      .search-page .btn-toolbar .btn-group #search-searchword:hover {
        background: transparent; }

.search-page .searchintro .badge {
  background: #CF4074; }

.search-page .search-results {
  padding: 20px 0 40px;
  border-top: 1px solid #CF4074; }
  .search-page .search-results .search-result {
    margin-bottom: 15px; }
    .search-page .search-results .search-result .result-title {
      margin-bottom: 5px; }
      .search-page .search-results .search-result .result-title * {
        color: #fff;
        vertical-align: middle; }
      .search-page .search-results .search-result .result-title span {
        display: inline-block;
        padding: 2px 5px;
        font-size: 13px;
        line-height: 27px;
        font-weight: bold;
        background: #CF4074;
        border-radius: 0 10px 10px 0; }
      .search-page .search-results .search-result .result-title a {
        padding: 2px 10px;
        background: #3e2d26;
        position: relative;
        margin-right: -4px; }
        .search-page .search-results .search-result .result-title a.google, .search-page .search-results .search-result .result-title a.waze {
          padding: 0 2px;
          background: none; }
          .search-page .search-results .search-result .result-title a.google img, .search-page .search-results .search-result .result-title a.waze img {
            width: 27px; }
        .search-page .search-results .search-result .result-title a.google {
          margin-right: 30px; }
        .search-page .search-results .search-result .result-title a .fa {
          height: 100%;
          padding: 0 2px;
          position: absolute;
          top: 0;
          left: -16px;
          background: #3e2d26;
          font-size: 14px;
          border-radius: 10px 0 0 10px;
          -webkit-transition: 0.3s all ease-in-out;
          -moz-transition: 0.3s all ease-in-out;
          transition: 0.3s all ease-in-out; }
          .search-page .search-results .search-result .result-title a .fa:before {
            line-height: 30px; }
        .search-page .search-results .search-result .result-title a:hover .fa {
          left: -18px; }
    .search-page .search-results .search-result .result-text {
      margin: 0 35px 0 0; }
      .search-page .search-results .search-result .result-text .highlight {
        color: #CF4074;
        font-weight: bold; }

.search-page .pagination {
  width: 100%;
  text-align: center; }

@media screen and (max-width: 400px) {
  .com_search .btn-toolbar,
  .id-162 .btn-toolbar {
    display: table-cell; }
    .com_search .btn-toolbar .btn-group,
    .id-162 .btn-toolbar .btn-group {
      width: 100%;
      float: none; }
      .com_search .btn-toolbar .btn-group:nth-child(1),
      .id-162 .btn-toolbar .btn-group:nth-child(1) {
        display: table-footer-group; }
      .com_search .btn-toolbar .btn-group:nth-child(2),
      .id-162 .btn-toolbar .btn-group:nth-child(2) {
        display: table-header-group; }
      .com_search .btn-toolbar .btn-group input,
      .com_search .btn-toolbar .btn-group button,
      .id-162 .btn-toolbar .btn-group input,
      .id-162 .btn-toolbar .btn-group button {
        width: 80%;
        display: block;
        margin: 0 auto;
        float: none;
        border-radius: 0;
        border: 1px solid #a2a2a2; } }

.login #main-component .login,
.login #main-component .registration,
.registration #main-component .login,
.registration #main-component .registration {
  max-width: 400px;
  margin: 60px auto 0; }
  .login #main-component .login form,
  .login #main-component .registration form,
  .registration #main-component .login form,
  .registration #main-component .registration form {
    background: #554c44;
    border-radius: 20px; }
    .login #main-component .login form .control-group,
    .login #main-component .registration form .control-group,
    .registration #main-component .login form .control-group,
    .registration #main-component .registration form .control-group {
      margin-bottom: 15px; }
      .login #main-component .login form .control-group.jform_spacer .control-label,
      .login #main-component .registration form .control-group.jform_spacer .control-label,
      .registration #main-component .login form .control-group.jform_spacer .control-label,
      .registration #main-component .registration form .control-group.jform_spacer .control-label {
        text-align: left; }
      .login #main-component .login form .control-group .control-label,
      .login #main-component .registration form .control-group .control-label,
      .registration #main-component .login form .control-group .control-label,
      .registration #main-component .registration form .control-group .control-label {
        margin: 0;
        padding: 0;
        vertical-align: middle; }
        .login #main-component .login form .control-group .control-label label,
        .login #main-component .registration form .control-group .control-label label,
        .registration #main-component .login form .control-group .control-label label,
        .registration #main-component .registration form .control-group .control-label label {
          color: #fff;
          font-weight: 100; }
          .login #main-component .login form .control-group .control-label label span.star,
          .login #main-component .registration form .control-group .control-label label span.star,
          .registration #main-component .login form .control-group .control-label label span.star,
          .registration #main-component .registration form .control-group .control-label label span.star {
            float: right;
            margin: 1px 0 0 4px;
            color: #d3386b; }
      .login #main-component .login form .control-group .controls,
      .login #main-component .registration form .control-group .controls,
      .registration #main-component .login form .control-group .controls,
      .registration #main-component .registration form .control-group .controls {
        vertical-align: middle; }
        .login #main-component .login form .control-group .controls *,
        .login #main-component .registration form .control-group .controls *,
        .registration #main-component .login form .control-group .controls *,
        .registration #main-component .registration form .control-group .controls * {
          width: 100%;
          height: 40px;
          border: 0;
          line-height: 40px;
          padding: 0 10px;
          border-radius: 10px;
          font-size: 20px; }
        .login #main-component .login form .control-group .controls .btn-primary,
        .login #main-component .login form .control-group .controls a,
        .login #main-component .registration form .control-group .controls .btn-primary,
        .login #main-component .registration form .control-group .controls a,
        .registration #main-component .login form .control-group .controls .btn-primary,
        .registration #main-component .login form .control-group .controls a,
        .registration #main-component .registration form .control-group .controls .btn-primary,
        .registration #main-component .registration form .control-group .controls a {
          margin-top: 20px;
          color: #fff;
          background-color: #d3386b;
          border-bottom: 1px solid #fff;
          border-top: 1px solid #fff;
          border-radius: 20px; }
      .login #main-component .login form .control-group.remember *,
      .login #main-component .registration form .control-group.remember *,
      .registration #main-component .login form .control-group.remember *,
      .registration #main-component .registration form .control-group.remember * {
        display: inline-block; }
        .login #main-component .login form .control-group.remember *.controls input,
        .login #main-component .registration form .control-group.remember *.controls input,
        .registration #main-component .login form .control-group.remember *.controls input,
        .registration #main-component .registration form .control-group.remember *.controls input {
          width: 15px;
          height: 15px; }
        .login #main-component .login form .control-group.remember *.control-label label,
        .login #main-component .registration form .control-group.remember *.control-label label,
        .registration #main-component .login form .control-group.remember *.control-label label,
        .registration #main-component .registration form .control-group.remember *.control-label label {
          margin: 0; }
      .login #main-component .login form .control-group.facebook a,
      .login #main-component .registration form .control-group.facebook a,
      .registration #main-component .login form .control-group.facebook a,
      .registration #main-component .registration form .control-group.facebook a {
        display: block;
        width: 100%; }
        .login #main-component .login form .control-group.facebook a .fa,
        .login #main-component .registration form .control-group.facebook a .fa,
        .registration #main-component .login form .control-group.facebook a .fa,
        .registration #main-component .registration form .control-group.facebook a .fa {
          width: 35px; }

.login #main-component .nav.nav-stacked,
.registration #main-component .nav.nav-stacked {
  max-width: 400px;
  margin: 0 auto;
  border: 0; }
  .login #main-component .nav.nav-stacked li > a,
  .registration #main-component .nav.nav-stacked li > a {
    margin: 9px 0;
    line-height: 24px;
    border: 0;
    background: #eac9d4;
    text-align: center;
    border-bottom: 2px solid #554c44;
    border-top: 2px solid #554c44;
    border-radius: 20px; }
    .login #main-component .nav.nav-stacked li > a:hover,
    .registration #main-component .nav.nav-stacked li > a:hover {
      background-color: #d3386b;
      color: #fff; }

.account #main-component {
  min-height: 45vh; }
  .account #main-component .container {
    background: rgba(255, 255, 255, 0.42); }
    .account #main-component .container .hikashop_cpanel .hk-row-fluid {
      text-align: center; }
      .account #main-component .container .hikashop_cpanel .hk-row-fluid .icon-wrapper {
        display: inline-block; }
        .account #main-component .container .hikashop_cpanel .hk-row-fluid .icon-wrapper .icon a {
          border: 0px;
          background: none; }
          .account #main-component .container .hikashop_cpanel .hk-row-fluid .icon-wrapper .icon a span {
            margin-top: 15px;
            color: #3e2d26;
            font-weight: bold; }
    .account #main-component .container .fa {
      background: #CF4074;
      font-size: 26px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      color: #fff;
      border-radius: 50%;
      text-align: center; }
    .account #main-component .container table {
      border-radius: 15px;
      overflow: hidden; }
      .account #main-component .container table th {
        border-top: 0;
        background: #3e2d26;
        color: #fff;
        white-space: nowrap; }
        .account #main-component .container table th,
        .account #main-component .container table th * {
          font-size: 16px;
          text-shadow: none;
          font-weight: 100;
          text-align: center; }
    .account #main-component .container .hikashop_search_block {
      height: 40px;
      margin: 25px 0; }
      .account #main-component .container .hikashop_search_block * {
        border: 0;
        float: right;
        height: 40px;
        border-radius: 0;
        color: #fff; }
      .account #main-component .container .hikashop_search_block #hikashop_search {
        background: #808080;
        padding: 0 10px;
        border-radius: 0 10px 10px 0; }
        .account #main-component .container .hikashop_search_block #hikashop_search::-webkit-input-placeholder {
          color: #3e2d26; }
        .account #main-component .container .hikashop_search_block #hikashop_search::-moz-placeholder {
          color: #3e2d26; }
        .account #main-component .container .hikashop_search_block #hikashop_search:-ms-input-placeholder {
          color: #3e2d26; }
        .account #main-component .container .hikashop_search_block #hikashop_search:-moz-placeholder {
          color: #3e2d26; }
      .account #main-component .container .hikashop_search_block .btn {
        background: #3e2d26;
        border-right: 2px solid #CF4074; }
        .account #main-component .container .hikashop_search_block .btn:hover {
          background: #CF4074; }
        .account #main-component .container .hikashop_search_block .btn:last-child {
          border-radius: 10px 0 0 10px; }
    .account #main-component .container .row.blog-title .fa {
      background: none; }
    .account #main-component .container .toolbar.hikashop_header_buttons .fa {
      display: block; }

.registration #main-component .registration {
  margin: 30px auto 0; }

@media screen and (max-width: 600px) {
  .login #side-icon,
  .registration #side-icon {
    display: none; } }

.hikashop_footer {
  display: none !important;
  width: 0 !important;
  height: 0 !important; }

.pseudo-breadcrumb {
  height: 50px;
  background: #CF4074;
  margin: 20px 0;
  border-radius: 35px;
  overflow: hidden; }
  .pseudo-breadcrumb * {
    display: inline-block;
    float: right;
    padding: 0 3px;
    line-height: 50px;
    background: grey; }
    .pseudo-breadcrumb *.active {
      color: #fff;
      padding-left: 20px; }
    .pseudo-breadcrumb *:first-child {
      padding-right: 20px; }

.reveal-shipping {
  text-align: center;
  margin-bottom: 20px; }
  .reveal-shipping dl {
    margin: 0; }
    .reveal-shipping dl dt {
      font-size: 26px;
      cursor: pointer;
      padding: 15px; }
    .reveal-shipping dl dd {
      margin: 0;
      display: none;
      padding: 15px 60px; }

.hikawishlist {
  display: none; }

.product-code {
  position: absolute;
  top: 0;
  left: 15px;
  line-height: 87px;
  color: #fff;
  font-weight: bold; }

.btn-cont {
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 5px; }
  .btn-cont a,
  .btn-cont .fa,
  .btn-cont button {
    float: left;
    padding: 0;
    margin: 0;
    line-height: 37px;
    background: #3e2d26;
    font-size: 18px;
    color: #fff;
    border: 0;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .btn-cont .fa {
    width: 10%; }
  .btn-cont button,
  .btn-cont a {
    width: 90%;
    display: block;
    border-radius: 0; }
  .btn-cont:hover a, .btn-cont:hover .fa, .btn-cont:hover button {
    background: #CF4074;
    color: #fff; }

@media screen and (max-width: 767px) {
  .hikashop_product_main_image_thumb {
    width: 100% !important;
    padding: 0 !important;
    max-height: 250px !important;
    overflow: hidden !important; }
  .hikashop_product_image_subdiv {
    width: 100% !important;
    padding: 0 !important;
    max-height: 170px !important;
    overflow: hidden !important; } }

.products-listing #side-icon,
.categories-listing #side-icon {
  display: none; }

.products-listing #main-component .hikashop_category_information > h1,
.categories-listing #main-component .hikashop_category_information > h1 {
  display: none; }

.products-listing #main-component .hikashop_category_information .before-listing,
.categories-listing #main-component .hikashop_category_information .before-listing {
  height: 50px;
  border-radius: 30px;
  margin: 30px -12px 10px;
  background: #CF4074;
  overflow: hidden; }
  .products-listing #main-component .hikashop_category_information .before-listing .breadcrumb,
  .categories-listing #main-component .hikashop_category_information .before-listing .breadcrumb {
    width: 70%;
    padding: 0;
    float: right;
    background: transparent;
    border-radius: 0;
    margin-bottom: 0; }
    .products-listing #main-component .hikashop_category_information .before-listing .breadcrumb a, .products-listing #main-component .hikashop_category_information .before-listing .breadcrumb p, .products-listing #main-component .hikashop_category_information .before-listing .breadcrumb i,
    .categories-listing #main-component .hikashop_category_information .before-listing .breadcrumb a,
    .categories-listing #main-component .hikashop_category_information .before-listing .breadcrumb p,
    .categories-listing #main-component .hikashop_category_information .before-listing .breadcrumb i {
      padding: 0 5px;
      display: inline-block;
      margin-bottom: 0;
      line-height: 50px;
      background: grey;
      float: right; }
      .products-listing #main-component .hikashop_category_information .before-listing .breadcrumb a.active, .products-listing #main-component .hikashop_category_information .before-listing .breadcrumb p.active, .products-listing #main-component .hikashop_category_information .before-listing .breadcrumb i.active,
      .categories-listing #main-component .hikashop_category_information .before-listing .breadcrumb a.active,
      .categories-listing #main-component .hikashop_category_information .before-listing .breadcrumb p.active,
      .categories-listing #main-component .hikashop_category_information .before-listing .breadcrumb i.active {
        color: #fff; }
      .products-listing #main-component .hikashop_category_information .before-listing .breadcrumb a:last-child, .products-listing #main-component .hikashop_category_information .before-listing .breadcrumb p:last-child, .products-listing #main-component .hikashop_category_information .before-listing .breadcrumb i:last-child,
      .categories-listing #main-component .hikashop_category_information .before-listing .breadcrumb a:last-child,
      .categories-listing #main-component .hikashop_category_information .before-listing .breadcrumb p:last-child,
      .categories-listing #main-component .hikashop_category_information .before-listing .breadcrumb i:last-child {
        padding-left: 20px; }
    .products-listing #main-component .hikashop_category_information .before-listing .breadcrumb i,
    .categories-listing #main-component .hikashop_category_information .before-listing .breadcrumb i {
      padding-right: 20px; }
  .products-listing #main-component .hikashop_category_information .before-listing .slider-pagination,
  .categories-listing #main-component .hikashop_category_information .before-listing .slider-pagination {
    width: 30%;
    height: 50px;
    float: right; }
    .products-listing #main-component .hikashop_category_information .before-listing .slider-pagination > *,
    .categories-listing #main-component .hikashop_category_information .before-listing .slider-pagination > * {
      height: 50px;
      position: relative;
      bottom: 0;
      display: inline-block;
      left: 0;
      margin: 0;
      padding: 0;
      float: right;
      line-height: 50px; }
      .products-listing #main-component .hikashop_category_information .before-listing .slider-pagination > *a,
      .categories-listing #main-component .hikashop_category_information .before-listing .slider-pagination > *a {
        width: 15%;
        margin: 0;
        display: block;
        background: #3e2d26;
        opacity: 1; }
        .products-listing #main-component .hikashop_category_information .before-listing .slider-pagination > *a .fa,
        .categories-listing #main-component .hikashop_category_information .before-listing .slider-pagination > *a .fa {
          line-height: 50px; }
        .products-listing #main-component .hikashop_category_information .before-listing .slider-pagination > *a:hover,
        .categories-listing #main-component .hikashop_category_information .before-listing .slider-pagination > *a:hover {
          background: grey; }
      .products-listing #main-component .hikashop_category_information .before-listing .slider-pagination > *ol,
      .categories-listing #main-component .hikashop_category_information .before-listing .slider-pagination > *ol {
        width: 70%;
        background: #fff; }
        .products-listing #main-component .hikashop_category_information .before-listing .slider-pagination > *ol li,
        .categories-listing #main-component .hikashop_category_information .before-listing .slider-pagination > *ol li {
          height: auto;
          text-indent: 0;
          float: right;
          border: 0;
          margin: 0;
          line-height: 45px;
          border-radius: 0; }
          .products-listing #main-component .hikashop_category_information .before-listing .slider-pagination > *ol li:hover,
          .categories-listing #main-component .hikashop_category_information .before-listing .slider-pagination > *ol li:hover {
            color: #CF4074; }

.products-listing #main-component .hikashop_category_information .reveal-shipping,
.categories-listing #main-component .hikashop_category_information .reveal-shipping {
  text-align: center; }
  .products-listing #main-component .hikashop_category_information .reveal-shipping dl,
  .categories-listing #main-component .hikashop_category_information .reveal-shipping dl {
    margin: 0; }
    .products-listing #main-component .hikashop_category_information .reveal-shipping dl dt,
    .categories-listing #main-component .hikashop_category_information .reveal-shipping dl dt {
      font-size: 26px;
      cursor: pointer;
      padding: 15px; }
    .products-listing #main-component .hikashop_category_information .reveal-shipping dl dd,
    .categories-listing #main-component .hikashop_category_information .reveal-shipping dl dd {
      margin: 0;
      display: none;
      padding: 15px 60px; }

.products-listing #main-component .hikashop_category_information .hikashop_products,
.products-listing #main-component .hikashop_category_information .hikashop_subcategories,
.categories-listing #main-component .hikashop_category_information .hikashop_products,
.categories-listing #main-component .hikashop_category_information .hikashop_subcategories {
  padding-bottom: 50px; }
  .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product,
  .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category,
  .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product,
  .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category,
  .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product,
  .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category,
  .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product,
  .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category {
    padding: 0; }
    .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container,
    .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container,
    .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container,
    .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container,
    .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container,
    .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container,
    .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container,
    .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container {
      margin: 0; }
      .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail,
      .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail,
      .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail,
      .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail,
      .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail,
      .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail,
      .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail,
      .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail {
        margin: 0;
        padding: 10px;
        background: transparent;
        border: 0; }
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikabtn.hikacart,
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikabtn.hikacart,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikabtn.hikacart,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikabtn.hikacart,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikabtn.hikacart,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikabtn.hikacart,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikabtn.hikacart,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikabtn.hikacart {
          width: 100%;
          background: #CF4074;
          border: 0;
          border-radius: 0; }
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name,
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name,
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name,
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name {
          display: block;
          background: #fff;
          border: 2px solid #CF4074;
          padding: 5px 0;
          position: relative;
          margin-bottom: 5px; }
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name a,
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name a,
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name a,
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name a,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name a,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name a,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name a,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name a,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name a,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name a,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name a,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name a,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name a,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name a,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name a,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name a {
            display: block; }
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star,
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star,
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star,
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star {
            position: absolute;
            top: 10px;
            color: #CF4074; }
            .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star.left,
            .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star.left,
            .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star.left,
            .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star.left,
            .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star.left,
            .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star.left,
            .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star.left,
            .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star.left,
            .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star.left,
            .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star.left,
            .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star.left,
            .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star.left,
            .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star.left,
            .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star.left,
            .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star.left,
            .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star.left {
              left: 10px; }
            .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star.right,
            .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star.right,
            .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star.right,
            .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star.right,
            .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star.right,
            .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star.right,
            .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star.right,
            .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star.right,
            .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star.right,
            .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star.right,
            .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star.right,
            .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star.right,
            .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star.right,
            .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star.right,
            .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name .fa-star.right,
            .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star.right {
              right: 10px; }
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image,
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image,
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image,
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image {
          height: auto;
          background: #fff;
          border: 2px solid #CF4074; }
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a,
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image .hikashop_product_image_subdiv,
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a,
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image .hikashop_product_image_subdiv,
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a,
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image .hikashop_product_image_subdiv,
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a,
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image .hikashop_product_image_subdiv,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image .hikashop_product_image_subdiv,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image .hikashop_product_image_subdiv,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image .hikashop_product_image_subdiv,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image .hikashop_product_image_subdiv,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image .hikashop_product_image_subdiv,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image .hikashop_product_image_subdiv,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image .hikashop_product_image_subdiv,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image .hikashop_product_image_subdiv,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image .hikashop_product_image_subdiv,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image .hikashop_product_image_subdiv,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image .hikashop_product_image_subdiv,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image .hikashop_product_image_subdiv {
            width: 100%; }
            .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a img,
            .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image .hikashop_product_image_subdiv img,
            .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a img,
            .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image .hikashop_product_image_subdiv img,
            .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a img,
            .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image .hikashop_product_image_subdiv img,
            .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a img,
            .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image .hikashop_product_image_subdiv img,
            .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a img,
            .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image .hikashop_product_image_subdiv img,
            .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a img,
            .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image .hikashop_product_image_subdiv img,
            .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a img,
            .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image .hikashop_product_image_subdiv img,
            .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a img,
            .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image .hikashop_product_image_subdiv img,
            .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a img,
            .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image .hikashop_product_image_subdiv img,
            .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a img,
            .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image .hikashop_product_image_subdiv img,
            .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a img,
            .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image .hikashop_product_image_subdiv img,
            .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a img,
            .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image .hikashop_product_image_subdiv img,
            .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a img,
            .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image .hikashop_product_image_subdiv img,
            .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a img,
            .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image .hikashop_product_image_subdiv img,
            .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a img,
            .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image .hikashop_product_image_subdiv img,
            .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a img,
            .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image .hikashop_product_image_subdiv img {
              width: 100%; }
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a,
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a,
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a,
          .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a,
          .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a,
          .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image a,
          .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_image a {
            padding: 0; }
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name,
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name,
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikabtn.hikacart,
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name,
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name,
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikabtn.hikacart,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikabtn.hikacart,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikabtn.hikacart,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikabtn.hikacart,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikabtn.hikacart,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikabtn.hikacart,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikabtn.hikacart {
          font-size: 26px;
          line-height: 32px; }
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_quantity_field,
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_quantity_field_change,
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_quantity_field,
        .products-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_quantity_field_change,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_quantity_field,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_quantity_field_change,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_quantity_field,
        .products-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_quantity_field_change,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_quantity_field,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_quantity_field_change,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_quantity_field,
        .categories-listing #main-component .hikashop_category_information .hikashop_products .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_quantity_field_change,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_quantity_field,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_quantity_field_change,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_quantity_field,
        .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_quantity_field_change {
          display: none; }

.categories-listing #main-component .hikashop_category_information .before-listing {
  display: none; }

.categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container {
  padding-bottom: 20px; }
  .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail br {
    display: none !important; }
  .categories-listing #main-component .hikashop_category_information .hikashop_subcategories .hikashop_category .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_category_name {
    margin-top: -15%;
    background: #CF4074;
    color: #fff; }

@media screen and (max-width: 500px) {
  .products-listing #main-component .hikashop_category_information .before-listing {
    height: auto;
    background: 0;
    border-radius: 0;
    margin: 5px 0; }
    .products-listing #main-component .hikashop_category_information .before-listing .breadcrumb {
      width: 100%;
      background: #CF4074;
      margin-bottom: 5px;
      border-radius: 10px;
      overflow: hidden; }
    .products-listing #main-component .hikashop_category_information .before-listing .slider-pagination {
      width: 100%;
      background: #fff; } }

.products-listing br {
  display: none; }

.products-listing form {
  margin: 0; }

.products-listing #pitputim-right {
  margin-bottom: 30px;
  padding: 0 5px;
  float: right; }
  .products-listing #pitputim-right #hikashop_product_top_part,
  .products-listing #pitputim-right #hikashop_product_right_part {
    border: 2px solid #CF4074;
    background: #fff; }
  .products-listing #pitputim-right #hikashop_product_top_part {
    position: relative;
    margin-bottom: 5px;
    text-align: center; }
    .products-listing #pitputim-right #hikashop_product_top_part h1 {
      line-height: 50px;
      font-size: 24px; }
    .products-listing #pitputim-right #hikashop_product_top_part .fa {
      position: absolute;
      top: 15px;
      color: #CF4074; }
      .products-listing #pitputim-right #hikashop_product_top_part .fa.left {
        left: 15px; }
      .products-listing #pitputim-right #hikashop_product_top_part .fa.right {
        right: 15px; }
  .products-listing #pitputim-right #hikashop_product_right_part {
    width: 100%;
    position: relative;
    padding: 20px 15px; }
    .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_quantity_main table {
      display: none; }
    .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_quantity_main .hikashop_product_quantity_field,
    .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_quantity_main .hikashop_product_quantity_field_change_plus,
    .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_quantity_main .hikashop_product_quantity_field_change_minus {
      margin: 0;
      position: absolute;
      height: 54px;
      line-height: 50;
      bottom: -2;
      right: 0;
      z-index: 2;
      background: #3e2d26;
      color: #fff;
      border: 0; }
      .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_quantity_main .hikashop_product_quantity_field .fa,
      .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_quantity_main .hikashop_product_quantity_field_change_plus .fa,
      .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_quantity_main .hikashop_product_quantity_field_change_minus .fa {
        line-height: 50px; }
    .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_quantity_main .hikashop_product_quantity_field {
      width: 40px;
      right: 36px;
      text-align: center; }
    .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_quantity_main .hikashop_product_quantity_field_change_plus {
      right: 76px;
      padding: 0 10px; }
    .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_quantity_main .hikashop_product_quantity_field_change_minus {
      padding: 0 10px; }
    .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_quantity_main .hikabtn.hikacart {
      width: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
      background: #CF4074;
      line-height: 40px;
      border-radius: 0;
      border: 0;
      font-size: 26px; }
    .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_price_main {
      margin-bottom: 45px;
      float: none; }
    .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_custom_info_main .product_custom,
    .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_price_main .product_custom {
      margin-bottom: 15px; }
      .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_custom_info_main .product_custom_title,
      .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_price_main .product_custom_title {
        width: 100%;
        border-bottom: 2px solid #3e2d26; }
        .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_custom_info_main .product_custom_title label,
        .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_price_main .product_custom_title label {
          margin: 0;
          background: #3e2d26;
          padding: 0 15px;
          color: #fff;
          border-radius: 10px 10px 0 0;
          font-weight: 100; }
      .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_custom_info_main .product_custom_content,
      .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_price_main .product_custom_content {
        padding: 5px 15px;
        background: rgba(207, 64, 116, 0.17); }
        .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_custom_info_main .product_custom_content span,
        .products-listing #pitputim-right #hikashop_product_right_part #hikashop_product_price_main .product_custom_content span {
          float: none;
          display: inline-block; }

.products-listing #hikashop_product_characteristics {
  margin: 0 -15px; }
  .products-listing #hikashop_product_characteristics .hikashop_variants_table_thead_tr th {
    background: #3e2d26;
    padding: 3px 5px;
    color: #fff;
    font-weight: 100;
    font-size: 16px;
    text-align: right; }
    .products-listing #hikashop_product_characteristics .hikashop_variants_table_thead_tr th.hikashop_product_name {
      display: none; }
    .products-listing #hikashop_product_characteristics .hikashop_variants_table_thead_tr th.hikashop_product_image, .products-listing #hikashop_product_characteristics .hikashop_variants_table_thead_tr th.hikashop_product_add_to_cart {
      width: 180px; }
  .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr:nth-child(2n+1) {
    background: #fdd8f3; }
  .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr:nth-child(2n) {
    background: #f9e5ec; }
  .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr td {
    border-bottom: 1px solid #3e2d26; }
    .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr td.hikashop_product_image_row img {
      max-width: 180px;
      max-height: 180px;
      border: 2px solid #3e2d26; }
    .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr td.hikashop_product_name_row {
      display: none; }
    .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr td.hikashop_product_add_to_cart_row {
      width: 260px; }
      .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr td.hikashop_product_add_to_cart_row .hikashop_product_quantity_field_change_plus,
      .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr td.hikashop_product_add_to_cart_row .hikashop_product_quantity_field_change_minus,
      .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr td.hikashop_product_add_to_cart_row .hikashop_product_quantity_field,
      .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr td.hikashop_product_add_to_cart_row .hikabtn.hikacart {
        height: 30px;
        padding: 0 10px;
        display: inline-block;
        background: #3e2d26;
        float: right;
        color: #fff;
        text-align: center; }
      .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr td.hikashop_product_add_to_cart_row .hikashop_product_quantity_field_change_plus,
      .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr td.hikashop_product_add_to_cart_row .hikashop_product_quantity_field_change_minus {
        width: 40px;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
        .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr td.hikashop_product_add_to_cart_row .hikashop_product_quantity_field_change_plus:hover,
        .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr td.hikashop_product_add_to_cart_row .hikashop_product_quantity_field_change_minus:hover {
          background: #CF4074; }
        .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr td.hikashop_product_add_to_cart_row .hikashop_product_quantity_field_change_plus .fa,
        .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr td.hikashop_product_add_to_cart_row .hikashop_product_quantity_field_change_minus .fa {
          line-height: 33px; }
      .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr td.hikashop_product_add_to_cart_row .hikashop_product_quantity_field_change_plus {
        border-radius: 0 10px 10px 0; }
      .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr td.hikashop_product_add_to_cart_row .hikashop_product_quantity_field {
        width: 45px;
        padding: 0;
        border: 0;
        border-left: 2px solid #CF4074;
        border-right: 2px solid #CF4074; }
      .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr td.hikashop_product_add_to_cart_row .hikabtn.hikacart {
        width: 100px;
        border: 0;
        border-radius: 10px 0 0 10px;
        font-size: 20px;
        background: #CF4074;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
        .products-listing #hikashop_product_characteristics .hikashop_variants_table_tbody tr td.hikashop_product_add_to_cart_row .hikabtn.hikacart:hover {
          background: #ff1469; }

.products-listing #pitputim-left {
  padding: 0 5px;
  float: left; }
  .products-listing #pitputim-left .hikashop_product_left_part {
    float: none;
    background: #fff;
    border: 2px solid #CF4074; }
    .products-listing #pitputim-left .hikashop_product_left_part #hikashop_product_image_main {
      position: relative; }
      .products-listing #pitputim-left .hikashop_product_left_part #hikashop_product_image_main #hikashop_main_image_div {
        display: block; }
      .products-listing #pitputim-left .hikashop_product_left_part #hikashop_product_image_main #hikashop_image_main_thumb_div {
        width: 100% !important;
        height: auto !important; }
        .products-listing #pitputim-left .hikashop_product_left_part #hikashop_product_image_main #hikashop_image_main_thumb_div div {
          max-width: 100% !important;
          height: auto !important; }
        .products-listing #pitputim-left .hikashop_product_left_part #hikashop_product_image_main #hikashop_image_main_thumb_div #hikashop_main_image {
          margin: 0 !important; }
    .products-listing #pitputim-left .hikashop_product_left_part #hikashop_small_image_div {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0; }

.products-listing .more-product-title {
  height: 50px;
  margin: 10px 0;
  background: #CF4074;
  border-radius: 30px;
  overflow: hidden; }
  .products-listing .more-product-title p {
    width: 250px;
    background: grey;
    margin: 0;
    color: #fff;
    text-align: center; }
    .products-listing .more-product-title p i, .products-listing .more-product-title p span {
      line-height: 50px;
      vertical-align: middle; }
    .products-listing .more-product-title p span {
      font-size: 28px; }

.products-listing #related-items {
  padding-bottom: 30px; }
  .products-listing #related-items .container .before-listing {
    display: none; }
  .products-listing #related-items .container .hikashop_product {
    padding: 0; }
    .products-listing #related-items .container .hikashop_product .hikashop_container {
      margin: 0; }
      .products-listing #related-items .container .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail {
        margin: 0;
        background: transparent;
        border: 0; }
        .products-listing #related-items .container .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikabtn.hikacart {
          width: 100%;
          background: #3e2d26;
          border: 0;
          border-radius: 0; }
        .products-listing #related-items .container .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name {
          background: #fff;
          border: 2px solid #CF4074;
          padding: 5px 0;
          position: relative;
          margin-bottom: 5px; }
          .products-listing #related-items .container .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name a {
            display: block; }
          .products-listing #related-items .container .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star {
            position: absolute;
            top: 10px;
            color: #CF4074; }
            .products-listing #related-items .container .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star.left {
              left: 10px; }
            .products-listing #related-items .container .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name .fa-star.right {
              right: 10px; }
        .products-listing #related-items .container .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image {
          height: auto;
          background: #fff;
          border: 2px solid #CF4074; }
          .products-listing #related-items .container .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image .hikashop_product_image_subdiv {
            width: 100%;
            padding: 10px; }
            .products-listing #related-items .container .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_image .hikashop_product_image_subdiv img {
              width: 100%; }
        .products-listing #related-items .container .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikashop_product_name,
        .products-listing #related-items .container .hikashop_product .hikashop_container .hikashop_subcontainer.thumbnail .hikabtn.hikacart {
          font-size: 26px;
          line-height: 32px; }

@media screen and (max-width: 992px) {
  .product-show #related-items .container .hikashop_product {
    width: 50%;
    float: right; } }

@media screen and (max-width: 767px) {
  .product-show #pitputim-right #hikashop_product_right_part #hikashop_product_description_main {
    height: auto;
    text-align: justify; } }

@media screen and (max-width: 500px) {
  .product-show #related-items .container .hikashop_product {
    width: 100%; } }

.page-id-133 .breadcrumb {
  padding: 0;
  background: #CF4074;
  margin: 20px 0;
  border-radius: 35px;
  overflow: hidden; }
  .page-id-133 .breadcrumb * {
    display: inline-block;
    background: none;
    margin: 0 !important;
    color: #fff;
    height: 50px;
    float: right;
    padding: 0 3px;
    line-height: 50px; }

.page-id-133 #side-icon {
  display: none; }

.page-id-133 #main-component {
  min-height: 70vh;
  padding: 20px 0 80px; }
  .page-id-133 #main-component .container {
    background: rgba(255, 255, 255, 0.39); }
    .page-id-133 #main-component .container input, .page-id-133 #main-component .container textarea, .page-id-133 #main-component .container select {
      width: 100%;
      padding: 0 10px;
      border: 1px solid #d8dadb;
      background: #f7cbeb;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .page-id-133 #main-component .container input:focus, .page-id-133 #main-component .container textarea:focus, .page-id-133 #main-component .container select:focus {
        background: #e5d3f2;
        border: 2px solid #CF4074;
        outline: hidden; }
    .page-id-133 #main-component .container label {
      font-size: 16px; }
    .page-id-133 #main-component .container p {
      font-size: 16px;
      font-weight: 100  !important; }
    .page-id-133 #main-component .container .col-sm-4 {
      padding: 0; }
    .page-id-133 #main-component .container #hikashop_checkout {
      background: #fff; }
      .page-id-133 #main-component .container #hikashop_checkout h1,
      .page-id-133 #main-component .container #hikashop_checkout h2 {
        margin-bottom: 10px;
        border-bottom: 1px solid #3e2d26; }
        .page-id-133 #main-component .container #hikashop_checkout h1 span,
        .page-id-133 #main-component .container #hikashop_checkout h2 span {
          font-weight: 600; }
        .page-id-133 #main-component .container #hikashop_checkout h1 .step-badge,
        .page-id-133 #main-component .container #hikashop_checkout h2 .step-badge {
          width: 30px;
          height: 30px;
          background: #CF4074;
          border-radius: 50% 50% 0 0;
          display: inline-block;
          text-align: center;
          line-height: 30px;
          color: #fff;
          font-size: 16px; }
      .page-id-133 #main-component .container #hikashop_checkout .block-sub-title {
        margin-bottom: 10px;
        font-weight: 600; }
      .page-id-133 #main-component .container #hikashop_checkout.hikashop_checkout_page_step2 a.hikabtn.hikabtn_checkout_guest_logout {
        display: none; }
      .page-id-133 #main-component .container #hikashop_checkout.hikashop_checkout_page_step2 #pitputim-login-selector {
        margin: 20px 0 30px;
        text-align: center;
        border-bottom: 1px solid #CF4074; }
        .page-id-133 #main-component .container #hikashop_checkout.hikashop_checkout_page_step2 #pitputim-login-selector ul {
          display: inline-block;
          text-align: center; }
          .page-id-133 #main-component .container #hikashop_checkout.hikashop_checkout_page_step2 #pitputim-login-selector ul li {
            display: inline-block;
            padding: 3px 10px;
            background: #3e2d26;
            color: #fff;
            float: right;
            cursor: pointer;
            -webkit-transition: 0.3s all ease-in-out;
            -moz-transition: 0.3s all ease-in-out;
            transition: 0.3s all ease-in-out; }
            .page-id-133 #main-component .container #hikashop_checkout.hikashop_checkout_page_step2 #pitputim-login-selector ul li:first-child {
              border-radius: 0 10px 0 0; }
            .page-id-133 #main-component .container #hikashop_checkout.hikashop_checkout_page_step2 #pitputim-login-selector ul li:nth-child(2) {
              border-left: 1px solid #fff;
              border-right: 1px solid #fff; }
            .page-id-133 #main-component .container #hikashop_checkout.hikashop_checkout_page_step2 #pitputim-login-selector ul li:last-child {
              border-radius: 10px 0 0 0; }
            .page-id-133 #main-component .container #hikashop_checkout.hikashop_checkout_page_step2 #pitputim-login-selector ul li.active, .page-id-133 #main-component .container #hikashop_checkout.hikashop_checkout_page_step2 #pitputim-login-selector ul li:hover {
              background: #CF4074; }
      .page-id-133 #main-component .container #hikashop_checkout.hikashop_checkout_page_step2 .pitputim-login {
        max-width: 450px;
        margin: 0 auto;
        float: none;
        padding-top: 6px;
        border-top: 2px solid #3e2d26; }
      .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-1,
      .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-4 {
        float: right; }
        .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-1 .hkform-group,
        .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-4 .hkform-group {
          margin-left: 0;
          margin-right: 0;
          position: relative; }
          .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-1 .hkform-group .fa-asterisk,
          .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-4 .hkform-group .fa-asterisk {
            position: absolute;
            top: 6px;
            left: 10px;
            z-index: 1;
            color: #3e2d26;
            font-size: 10px; }
        .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-1 #hikashop_checkout_registration .hkc-lg-12,
        .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-4 #hikashop_checkout_registration .hkc-lg-12 {
          float: none;
          clear: both; }
        .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-1 #hikashop_checkout_registration .hikashop_registration_required_info_line span,
        .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-4 #hikashop_checkout_registration .hikashop_registration_required_info_line span {
          font-size: 16px; }
        .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-1 #hikashop_checkout_login_form .hikashop_login_remember_line,
        .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-4 #hikashop_checkout_login_form .hikashop_login_remember_line {
          height: 25px; }
          .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-1 #hikashop_checkout_login_form .hikashop_login_remember_line input,
          .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-4 #hikashop_checkout_login_form .hikashop_login_remember_line input {
            width: 22px;
            height: 22px;
            float: right;
            margin: 0; }
          .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-1 #hikashop_checkout_login_form .hikashop_login_remember_line label,
          .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-4 #hikashop_checkout_login_form .hikashop_login_remember_line label {
            margin: 0 5px 0 0; }
        .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-1 #hikashop_checkout_login_form .forg,
        .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-1 #hikashop_checkout_login_form .hikashop_login_button_line,
        .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-4 #hikashop_checkout_login_form .forg,
        .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-4 #hikashop_checkout_login_form .hikashop_login_button_line {
          margin: 0;
          clear: both; }
        .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-1 #hikashop_checkout_login_form .hikashop_login_button_line,
        .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-4 #hikashop_checkout_login_form .hikashop_login_button_line {
          margin-top: 20px; }
        .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-1 #hikashop_checkout_login_form .forg,
        .page-id-133 #main-component .container #hikashop_checkout .hkc-lg-4 #hikashop_checkout_login_form .forg {
          clear: both; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_address h2 {
        margin: 0; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_address input[type='radio'] {
        width: 20px;
        margin-top: 14px;
        float: right; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_address legend {
        width: auto;
        margin-right: -10px;
        padding: 0 10px;
        display: inline-block;
        border-radius: 0 0 10px 10px;
        background: #3e2d26;
        color: #fff;
        font-size: 18px; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_address button {
        background: #3e2d26;
        width: 100%;
        color: #fff;
        border: 0;
        border-radius: 0 0 10px 10px;
        border-top: 2px solid #CF4074;
        font-size: 14px; }
      .page-id-133 #main-component .container #hikashop_checkout #hikashop_address_1_0_address_title {
        display: none; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart {
        float: none;
        clear: both; }
        .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table {
          width: 100%;
          border: 0; }
          .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table * {
            text-align: right;
            font-size: 16px; }
          .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table thead tr {
            background: #3e2d26; }
            .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table thead tr th {
              padding: 3px 5px;
              color: #fff;
              font-weight: 100;
              font-size: 16px; }
          .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tbody tr {
            border-bottom: 1px solid #fff; }
            .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tbody tr:nth-child(2n) {
              background: #f9e5ec; }
            .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tbody tr:nth-child(2n+1) {
              background: #fdd8f3; }
            .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tbody tr td {
              padding: 2px 5px; }
              .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tbody tr td.hikashop_cart_product_image_value * {
                text-align: center; }
              .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tbody tr td.hikashop_cart_product_quantity_value .hikashop_product_quantity_field {
                width: 50px;
                padding: 0;
                background: #3e2d26;
                border-radius: 10px;
                text-align: center;
                color: #fff; }
              .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tbody tr td.hikashop_cart_product_quantity_value .hikashop_product_quantity_field,
              .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tbody tr td.hikashop_cart_product_quantity_value .hikashop_cart_product_quantity_refresh,
              .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tbody tr td.hikashop_cart_product_quantity_value .hikashop_cart_product_quantity_delete {
                display: inline-block; }
              .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tbody tr td.hikashop_cart_product_image_value img {
                max-width: 90px;
                max-height: 90px;
                border: 2px solid #3e2d26; }
          .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tfoot tr:first-child td {
            border-top: 2px solid #3e2d26; }
          .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tfoot tr td {
            padding: 3px 5px;
            border: 0; }
            .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tfoot tr td:not(.hikashop_cart_empty_footer) {
              background: #3e2d26;
              color: #fff;
              border-bottom: 1px solid #fff; }
            .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tfoot tr td.hikashop_cart_title {
              padding-left: 20px;
              text-align: left; }
            .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tfoot tr td.hikashop_cart_total_title.hikashop_cart_title {
              background: #CF4074;
              border-radius: 0 0 15px 0;
              color: #fff;
              font-size: 20px; }
            .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tfoot tr td.hikashop_cart_total_value {
              background: #CF4074;
              border-radius: 0 0 0 15px; }
              .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tfoot tr td.hikashop_cart_total_value span {
                color: #fff;
                font-size: 20px; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_coupon input {
        margin: 0; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_coupon .btn-cont {
        width: 30%;
        border-radius: 0 0 10px 10px;
        float: left; }
        .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_coupon .btn-cont button {
          width: 100%;
          font-size: 16px; }
      .page-id-133 #main-component .container #hikashop_checkout .container-shipping {
        margin-top: 20px;
        padding-right: 0; }
      .page-id-133 #main-component .container #hikashop_checkout .container-payment {
        margin-top: 20px;
        padding-left: 0; }
      .page-id-133 #main-component .container #hikashop_checkout .container-fields {
        margin-bottom: 30px;
        padding-right: 0;
        clear: both; }
      .page-id-133 #main-component .container #hikashop_checkout .container-coupon {
        padding-right: 0; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_payment *,
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_shipping * {
        border: 0;
        background: transparent;
        vertical-align: middle; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_payment img,
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_shipping img {
        width: 25px; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_payment input,
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_payment label,
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_shipping input,
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_shipping label {
        margin: 0;
        line-height: 15px;
        vertical-align: middle; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_payment input,
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_shipping input {
        width: 15px;
        height: 15px; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_payment label span,
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_shipping label span {
        height: 15px;
        line-height: 20px; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_payment span,
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_shipping span {
        font-size: 14px; }
      .page-id-133 #main-component .container #hikashop_checkout #hikashop_order_3_1_delivery_date {
        width: 50%;
        display: inline-block;
        vertical-align: top; }
        .page-id-133 #main-component .container #hikashop_checkout #hikashop_order_3_1_delivery_date .input-append {
          position: relative; }
          .page-id-133 #main-component .container #hikashop_checkout #hikashop_order_3_1_delivery_date .input-append #delivery_date_btn {
            position: absolute;
            left: 0;
            top: 0;
            width: 80px;
            height: 31px;
            background: #3e2d26;
            border-radius: 0 10px 10px 0; }
            .page-id-133 #main-component .container #hikashop_checkout #hikashop_order_3_1_delivery_date .input-append #delivery_date_btn:before {
              display: block;
              content: "\f073";
              margin-top: -2px;
              font-family: FontAwesome;
              color: #fff;
              line-height: 18px;
              font-size: 18px; }
            .page-id-133 #main-component .container #hikashop_checkout #hikashop_order_3_1_delivery_date .input-append #delivery_date_btn:hover {
              background: #CF4074; }
        .page-id-133 #main-component .container #hikashop_checkout #hikashop_order_3_1_delivery_date .js-calendar .calendar-container {
          border-radius: 0 0 5px 5px;
          background-color: #3e2d26 !important;
          color: #fff;
          border: 2px solid #CF4074; }
          .page-id-133 #main-component .container #hikashop_checkout #hikashop_order_3_1_delivery_date .js-calendar .calendar-container .table {
            border-radius: 0; }
            .page-id-133 #main-component .container #hikashop_checkout #hikashop_order_3_1_delivery_date .js-calendar .calendar-container .table .today::after {
              background-color: #cf4074; }
            .page-id-133 #main-component .container #hikashop_checkout #hikashop_order_3_1_delivery_date .js-calendar .calendar-container .table .selected {
              background: #CF4074; }
          .page-id-133 #main-component .container #hikashop_checkout #hikashop_order_3_1_delivery_date .js-calendar .calendar-container .buttons-wrapper.btn-group {
            width: 100%;
            text-align: center; }
            .page-id-133 #main-component .container #hikashop_checkout #hikashop_order_3_1_delivery_date .js-calendar .calendar-container .buttons-wrapper.btn-group a {
              float: none; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_fields_button_line {
        display: inline-block;
        width: 50%;
        vertical-align: top; }
        .page-id-133 #main-component .container #hikashop_checkout .hikashop_fields_button_line .hkc-sm-4 {
          display: none; }
        .page-id-133 #main-component .container #hikashop_checkout .hikashop_fields_button_line .hkc-sm-8 {
          width: 100%; }
          .page-id-133 #main-component .container #hikashop_checkout .hikashop_fields_button_line .hkc-sm-8 button {
            width: 100%;
            display: block;
            color: #fff;
            background-color: #cf4074;
            font-size: 20px;
            padding: 1px;
            border: 0px;
            border-radius: 0px; }
      .page-id-133 #main-component .container #hikashop_checkout #hikashop_checkout_fields_3_6 {
        float: none;
        clear: both;
        padding: 0; }
        .page-id-133 #main-component .container #hikashop_checkout #hikashop_checkout_fields_3_6 .hikashop_checkout_terms {
          text-align: left; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_fields {
        padding: 20px 0; }
        .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_fields .hikashop_checkout_delivery_date_line .hkradio-inline {
          padding: 0 20px 0 0;
          margin-left: 10px; }
          .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_fields .hikashop_checkout_delivery_date_line .hkradio-inline input {
            width: 20px;
            margin: 4px -20px 0 4px; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_status {
        clear: both;
        text-align: right;
        direction: rtl; }
        .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_status .label.label-info {
          background: #CF4074; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_fields .hikashop_checkout_terms {
        clear: both; }
        .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_fields .hikashop_checkout_terms .hikashop_checkout_terms_checkbox,
        .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_fields .hikashop_checkout_terms .hikashop_checkout_terms_checkbox_1_7 {
          display: inline-block; }
        .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_fields .hikashop_checkout_terms .hikashop_checkout_terms_checkbox {
          width: 24px !important; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_buttons .buttons_left.btn-cont,
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_buttons .buttons_right.btn-cont {
        width: 250px; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_buttons .buttons_left.btn-cont {
        float: right; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_buttons .buttons_right.btn-cont {
        float: left; }

@media screen and (max-width: 992px) {
  .page-id-133 #main-component {
    padding: 20px 0; }
    .page-id-133 #main-component .container #hikashop_checkout .col-sm-4,
    .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_login .hkc-lg-4 {
      width: 100%; }
    .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart {
      width: 100%;
      display: inline-block; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tfoot tr td.hikashop_cart_total_title.hikashop_cart_title, .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tfoot tr td.hikashop_cart_total_value, .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tfoot tr td.hikashop_cart_shipping_title, .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tfoot tr td.hikashop_cart_shipping_value, .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tfoot tr td.hikashop_cart_subtotal_value, .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tfoot tr td.hikashop_cart_subtotal_title {
        width: 50%;
        float: right; }
      .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart table tfoot tr td:before {
        display: none; }
    .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_buttons .buttons_right.btn-cont button {
      height: 80px;
      background: #CF4074;
      margin-top: 15px;
      border-radius: 10px; } }

@media screen and (max-width: 767px) {
  .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_address .hk-container-fluid .hkc-sm-6 {
    margin-bottom: 15px; }
  .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_cart,
  .page-id-133 #main-component .container #hikashop_checkout .container-shipping,
  .page-id-133 #main-component .container #hikashop_checkout .container-payment,
  .page-id-133 #main-component .container #hikashop_checkout .container-coupon {
    width: 100%;
    padding: 0;
    margin-bottom: 15px; }
  .page-id-133 #main-component .container #hikashop_checkout #hikashop_checkout_fields_3_6 .hikashop_checkout_terms {
    text-align: right; }
  .page-id-133 #main-component .container #hikashop_checkout #hikashop_order_3_1_delivery_date {
    width: 100%; }
  .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_buttons {
    width: 100%; }
    .page-id-133 #main-component .container #hikashop_checkout .hikashop_checkout_buttons .buttons_right.btn-cont button {
      height: 50px;
      width: 100%; }
  .hikashop_checkout_cart table tfoot tr td:not(.hikashop_cart_empty_footer) {
    padding-left: 10px !important; } }

.gallery-page #side-icon {
  display: none; }

.gallery-page #main-component .uk-panel-teaser,
.gallery-page #main-component .uk-flex-wrap.uk-clearfix.uk-margin {
  margin: 0; }

.gallery-page #main-component .uk-flex-wrap.uk-clearfix.uk-margin * {
  width: 100%;
  padding: 0; }
  .gallery-page #main-component .uk-flex-wrap.uk-clearfix.uk-margin * a {
    background: #CF4074;
    color: #fff; }

.gallery-page #main-component .uk-subnav.uk-flex-center {
  overflow: hidden; }
  .gallery-page #main-component .uk-subnav.uk-flex-center li {
    background: #3e2d26;
    padding: 5px 20px;
    border-left: 2px solid #CF4074; }
    .gallery-page #main-component .uk-subnav.uk-flex-center li a {
      color: #fff !important; }
    .gallery-page #main-component .uk-subnav.uk-flex-center li.uk-active {
      background: #CF4074; }
    .gallery-page #main-component .uk-subnav.uk-flex-center li:first-child {
      border-radius: 0 10px 10px 0; }
    .gallery-page #main-component .uk-subnav.uk-flex-center li:last-child {
      border-radius: 10px 0 0 10px;
      border-left: 0; }

.gallery-page .uk-overlay-hover:hover .uk-overlay-fade {
  opacity: 0.3 !important; }

.gallery-page .uk-modal.uk-open .uk-slidenav-position .uk-slidenav {
  background: #CF4074;
  color: #fff; }
  .gallery-page .uk-modal.uk-open .uk-slidenav-position .uk-slidenav.uk-slidenav-previous {
    left: 0;
    border-radius: 0 50% 50% 0; }
  .gallery-page .uk-modal.uk-open .uk-slidenav-position .uk-slidenav.uk-slidenav-next {
    right: 0;
    border-radius: 50% 0 0 50%; }

body,
html {
	overflow-x: hidden; }
body {
	direction: rtl;
	min-height: 100vh;
	* {
		direction: rtl; } }
ul {
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		display: inline-block; } }
img {
	max-width: 100%; }

a, p span, div, h1, h2,h3,h4, td, th, li {
	font-family: "Open Sans Hebrew";
	font-size: 20px;
	margin: 0; }

a {
	color: inherit;
	text-decoration: none;
	&:hover {
		color: inherit;
		text-decoration: none; } }

.container {
	position: relative; }


.mobile {
	display: none !important; }

div[class^="col"] {
	float: right; }


.alert {
	background: #d2396b;
	a, h4, div {
		color: #fff; }
	a.close {
		float: left;
		opacity: 1; } }

#cart {
	width: 100%;
	position: fixed;
	top: 245px;
	left: 0;
	padding: 0;
	background: $scolor;
	z-index: 3;
	max-height: 0;
	overflow: hidden;
	@include transition-attr(all, .5s);
	&.show {
		padding: 20px 0;
		max-height: 2000px;
		overflow: visible; }
	* {
		color: #fff; }
	#hikashop_cart_module {
		width: 500px;
		margin: 0 auto;
		.hikashop_cart {
			text-align: center; }

		table.hikashop_cart {
			margin-bottom: 15px;
			* {
				text-align: right;
				font-size: 16px; }
			thead tr {
				background: rgba(207, 64, 116, 0.38);
				th {
					padding: 3px 5px; } }
			tbody tr {
				border-bottom: 1px solid #fff;
				&:nth-child(2n) {
					background: rgba(227, 227, 227, 0.28); }
				&:nth-child(2n+1) {
					background: rgba(227, 227, 227, 0.5); }
				td {
					padding: 2px 5px;
					input {
						background: transparent;
						border: 0;
						text-align: center; } } }
			tfoot tr {
				td {
					padding: 3px 5px;
					&.hikashop_cart_module_product_total_title {
						background: $fcolor;
						border-radius: 0 0 5px 0; }
					&.hikashop_cart_module_product_total_value {
						background: $fcolor;
						border-radius: 0 0 0 5px; } } } } }
	.btns a {
		background: none;
		float: left;
		overflow: hidden;
		@include transition-attr(.3s, all);

		span, .fa {
			padding: 3px 10px;
			display: inline-block;
			vertical-align: top;
			line-height: 30px;
			float: right;
			background: hsla(338, 60%, 53%, 0.4); }
		&#to-shop {
			margin-left: 17px;
			float: left;
			border-radius: 5px 0 0 5px; }

		&.pay {
			margin-left: 17px;
			float: left;
			border-radius: 5px; }

		&:hover {
			background: $fcolor; } } }

#cart.sticky {
	top: 145px; }

#top {
	width: 100%;
	position: fixed;
	z-index: 9;
	top: 0;
	left: 0;
	background: $fcolor;

	.top-menu .row {
		position: relative;
		a,p {
			margin: 0;
			display: inline-block;
			line-height: 55px;
			color: #fff;
			&.space {
				margin: 0 5px; } }

		.search-form {
			position: relative;
			z-index: 2;
			form {
				width: 100%;
				position: absolute;
				top: 20px;
				right: 0;
				background: $fcolor;
				border: 5px solid $fcolor;
				border-radius: 0 0 0 15px;
				.fa {
					position: absolute;
					right: 5px;
					top: 7px;
					font-size: 26px;
					color: grey;
					cursor: pointer; }
				.search-query {
					width: 100%;
					height: 40px;
					border: 0;
					border-radius: 10px;
					padding: 0 45px 0 10px; } } }
		.login {
			text-align: left;
			* {
				display: inline; }
			.space {
				margin: 0; }
			a .fa {
				margin-right: 10px; } }

		.phone {
			text-align: center;
			* {
				display: inline-block; }
			p {
				font-size: 24px; }
			.fa-cont {
				width: 35px;
				height: 35px;
				margin-right: 6px;
				border-radius: 50%;
				border: 1px solid #fff;
				text-align: center;

				.fa {
					line-height: 35px;
					font-size: 20px; } } }

		.icons {
			padding: 0;
			position: absolute;
			left: 0;
			bottom: -35px;
			z-index: 2;
			text-align: left;
			a {
				width: 50px;
				height: 50px;
				display: inline-block;
				background: $fcolor;
				border-radius: 50%;
				border: 3px solid #fff;
				text-align: center;
				position: relative;
				@include transition-attr(.3s, all);

				.tooltip {
					position: absolute;
					top: -40px;
					left: 30%;
					padding: 5px;
					line-height: 20px;
					background: $scolor;
					border-radius: 5px;
					white-space: nowrap;
					opacity: 0;
					max-height: 0;
					overflow: hidden;
					@include transition-attr(.3s, all);

					* {
						font-size: 16px; }

					.fa {
						position: absolute;
						line-height: 10px;
						bottom: -6px;
						left: 5px;
						color: $scolor; } }

				.fa {
					line-height: 40px;
					font-size: 23px; }
				&:hover {
					background: $scolor;
					.tooltip {
						opacity: 1;
						max-height: 100px;
						overflow: visible; } } }




			a.search {
				display: none; }

			a.cart {
				position: relative;

				.cart-counter {
					width: 30px;
					height: 30px;
					position: absolute;
					bottom: -15px;
					left: -15px;
					background: $scolor;
					text-align: center;
					line-height: 30px;
					font-size: 13px;
					font-weight: bold;
					border-radius: 50%;
					@include transition-attr(.3s, all); }

				&.toggled {
					background: $scolor;
					&:hover {
						background: $scolor; }

					.cart-counter {
						background: $fcolor; } } } } }

	.logo {

		background: #fff;
		text-align: center;
		.col-sm-4 {
			margin-right: 33.33333333%;
			a {
				display: block;

				img {
					max-width: 350px;
					margin-top: -45px;
					@include transition-attr(all, .5s); } } } } }
#top.sticky {
	.logo a img {
		max-width: 140px;
		margin-top: -45px; } }


header {
	width: 100%;
	padding: 20px 0;
	position: fixed;
	top: 170px;
	left: 0px;
	z-index: 6;
	background: #fff;
	@include transition-attr(all, .5s);
	.mobile-menu {
		display: none;
		.fa {
			padding: 15px;
			cursor: pointer;
			font-size: 34px;
			background: $scolor;
			color: #fff; } }

	nav .nav.menu li {
		display: inline-block;
		padding: 4px 20px;
		position: relative;
		float: right;
		a {
			display: inline-block;
			padding: 0;
			font-size: 18px;
			color: $scolor; }

		&:hover a {
			color: $fcolor;
			background: inherit; }

		&.current {
			border-bottom: 2px solid $fcolor; }

		&.parent {
			ul {
				width: 200px;
				max-height: 0;
				position: absolute;
				top: 30px;
				right: 0;
				overflow: hidden;
				background: #fff2f2;
				-webkit-transition: all ease-in-out 1s;
				-moz-transition: all ease-in-out 1s;
				-ms-transition: all ease-in-out 1s;
				-o-transition: all ease-in-out 1s;
				transition: all ease-in-out 1s;
				z-index: 5;

				li {
					width: 100%;
					display: block;
					background: $scolor;
					a {
						color: #fff; }

					&:hover {
						background: $fcolor; }


					&:after {
						display: none; } } }


			&:hover ul {
				max-height: 1000px; } }

		&:not(:last-child):after {
			content: '■';
			width: 6px;
			height: 6px;
			position: absolute;
			left: -4px;
			top: 15px;
			font-size: 12px;
			line-height: 6px; } } }

header.sticky {
	top: 75px;
	border-bottom: 2px solid $fcolor; }

header + section {
	margin-top: 260px; }



#main-component {
	direction: rtl;
	position: relative;
	.bg {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		opacity: 0.1;
		background: url(../images/imageBG.jpg) no-repeat; }
	aside {
		background: url(../images/formBG.png); } }
#map {
	position: relative;
	z-index: 1; }

footer {
	position: relative;
	z-index: 1;
	padding: 30px 60px 10px;
	direction: rtl;
	background: $scolor;
	border-top: 5px solid #fad3dd;
	* {
		color: #fff; }
	.row {
		padding: 20px 0;
		& > div {
			float: right;
			&.menu ul {
				width: 33%;
				float: right;
				display: inline-block;
				li {
					width: 100%;
					a {
						line-height: 30px; } } }
			&.adress {
				border: {
					top: 4px dotted #fff;
					bottom: 4px dotted #fff; } }
			&.maillist {
				& > p {
					padding: 0 10px;
					margin-top: -25px;
					border-bottom: 2px solid $fcolor;
					.fa {
						font-size: 18px;
						margin-left: 5px; } }
				form {
					margin: 0; }
				.left,
				.right {
					height: 122px; }

				.left {
					width: 65%;
					float: left;
					input {
						width: 100%!important;
						color: $scolor;
						padding: 1px 5px 1px 0; } }

				.right {
					width: 35%;
					float: right;

					input {
						height: 100%;
						width: 90%;
						background: grey;
						color: #fff;
						border: 0;
						float: right;
						&:hover {
							background: $fcolor; } } } } } } }

#side-icon {
	position: fixed;
	right: 5px;
	z-index: 3;
	opacity: 0;
	@include transition-attr(.5s, all);
	a {
		display: block; } }

.uk-modal-dialog.uk-modal-dialog-lightbox.uk-slidenav-position {
	position: relative;
	.uk-modal-close.uk-close.uk-close-alt {
		position: absolute;
		left: 0;
		top: 0;
		color: $fcolor;
		background: #fff;
		border-radius: 0 0 10px;
		&:after {
			opacity: 1; } } }


.blog-title {
	width: 100%;
	height: 50px;
	background: #c84776;
	margin-bottom: 20px;
	border-radius: 35px;
	overflow: hidden;
	margin: 30px 0;
	h1 {
		width: 300px;
		padding-right: 30px;
		background: grey;
		line-height: 50px;
		font-size: 26px;
		color: #fff; } }

.notifyjs-corner {
	margin: 0;
	.notifyjs-metro-base.notifyjs-metro-info {
		background: $fcolor;
		border: 2px solid $scolor;
		* {
			color: #fff;
			text-align: right; }
		.image {
			right: 0;
			left: auto;
			text-align: right; }

		.text-wrapper {
			margin: 10px 100px 10px 10px; } } }

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1500px) {
	footer {
		padding: 30px 0 10px;
		.row {
			text-align: center;
			padding: 0;

			&> div {
				width: 100%;
				max-width: 800px;
				display: inline-block;
				float: none;
				clear: both;
				margin: 0 auto;
				text-align: center;
				padding: 12px 0;
				&.maillist {
					& > p {
						margin: 10px 0 0; } } } } } }





@media screen and (max-width: 1350px) {
	#top {
		padding-top: 55px;
		.phone-icons {
			width: 83%;
			height: 50px;
			.icons {
				width: 100%;
				text-align: left;
				a {
					margin: 0 !important; } } }

		.top-menu .row .search-form form {
			top: -35px; }

		.phone,
		.login {
			display: none; }
		.mobile {
			display: inline-block !important; } } }



@media screen and (max-width: 1190px) {

	header {
		padding: 0;
		background: $scolor;
		z-index: 10 !important;
		.container {
			width: 100%;
			nav {
				width: 100%;
				height: 100%;
				position: fixed;
				top: 0;
				right: -300%;
				background: #fff;
				padding: 20px 0;
				z-index: 99;
				@include transition-attr(.3s, all);
				.nav.menu {
					display: block;
					width: 100%;
					li {
						width: 100%;
						display: block;
						padding: 7px 20px;
						background: $scolor;
						border-bottom: 2px solid #fff;
						a {
							display: block;
							color: #fff; }

						&.current {
							background: $fcolor; }

						&:after {
							display: none; } } }
				.mobile.close {
					width: 100%;
					display: block !important;
					background: $scolor;
					opacity: 1;
					text-align: center;
					border-radius: 0 0 20px 20px;
					.fa {
						font-size: 40px;
						margin-bottom: -30px;
						background: $scolor;
						border-radius: 50%;
						color: #fff;
						text-shadow: none;
						border: 6px solid $scolor; } } }


			nav.show {
				right: 0; }

			.mobile-menu {
				display: block; } } }

	#top.sticky .top-menu .row .icons {
		bottom: 2px; } }

@media screen and (max-width: 992px) {
	#top {
		.top-menu .row {
			.search-form {
				display: none; }
			.phone-icons {
				width: 100%;
				float: left;
				.icons {
					width: 100%;
					bottom: -25px;
					a {
						width: 50px;
						height: 50px;
						border: 2px solid #fff;
						.fa {
							line-height: 45px;
							font-size: 26px; } }
					a.search {
						display: inline-block; } } }

			.icons {
				bottom: 3px; } }

		.logo .col-sm-4 {
			margin: 0;
			a img {
				margin: 0;
				max-width: 200px; } } }
	header,
	header.sticky {
		top: 145px; }

	header+section {
		margin-top: 215px; } }

@media screen and (max-width: 767px) {
	#side-icon {
		display: none; }

	#top {
		.top-menu .row .phone-icons {
			height: 60px;
			padding: 5px 0;
			.icons {
				position: relative;
				bottom: 0; } } }


	footer .row>div {
		&.menu ul li a {
			font-size: 15px;
			display: block; }

		&.adress * {
			font-size: 16px; }

		&.maillist {
			padding: 5px;
			.left,
			.right {
				width: 100%;
				max-width: 500px;
				height: auto;
				margin: 0 auto !important;
				float: none; }

			.right {
				margin-top: 5px;
				input {
					height: 45px;
					width: 100%;
					background: $fcolor; } } } } }




@media screen and (max-width: 550px) {
	#cart {
		top: 210px;

		&.sticky {
			top: 110px; }

		#hikashop_cart_module {
			width: 100%; } }

	#top {
		.top-menu .row .icons {
			width: 100%;
			bottom: 8px;
			text-align: center;
			a {
				width: 40px;
				height: 40px;

				.fa {
					line-height: 33px;
					font-size: 19px; } } }

		&.sticky {
			padding-top: 0;
			.logo .col-sm-4 a img {
				max-width: 140px; }

			.top-menu .row .icons {
				display: none; } } }

	header.sticky {
		top: 65px;
		.mobile-menu .fa {
			padding: 5px; } } }



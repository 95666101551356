$fcolor: #CF4074;
$scolor: #3e2d26;

@mixin transition-flip() {
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
	filter: FlipH;
	-ms-filter: "FlipH"; }

@mixin transition-attr($time, $attr) {
	-webkit-transition: $attr $time ease-in-out;
	-moz-transition: $attr $time ease-in-out;
	transition: $attr $time ease-in-out; }

@mixin transition-scale($sizeX, $sizeY) {
	-moz-transform: scale($sizeX, $sizeY);
	-o-transform: scale($sizeX, $sizeY);
	-webkit-transform: scale($sizeX, $sizeY);
	transform: scale($sizeX, $sizeY); }

//********************************Input Default**********************************//
@mixin inputDefault() {
	padding: 0 10px;
	border: 1px solid #d8dadb;
	background: #f7cbeb;
	@include transition-attr(.3s, all);
	&:focus {
		background: #e5d3f2;
		border: 2px solid $fcolor;
		outline: hidden; } }

//-----------------------------End of Input Default------------------------------//
//********************************Button Default**********************************//
@mixin buttonDefault() {
	margin: 0 0 5px;
	text-align: center;
	vertical-align: top;
	background: #4d4d4d;
	border-radius: 0;
	line-height: 25px;
	color: #fff;
	font-size: 18px;
	@include transition-attr(.3s, all);
	&:hover {
		background: $fcolor;
		color: #4d4d4d;
		span {
			color: #4d4d4d; } } }
//-----------------------------End of Button Default------------------------------//

.hikashop_footer {
	display: none !important;
	width: 0 !important;
	height: 0 !important; }

.pseudo-breadcrumb {
	height: 50px;
	background: $fcolor;
	margin: 20px 0;
	border-radius: 35px;
	overflow: hidden;
	* {
		display: inline-block;
		float: right;
		padding: 0 3px;
		line-height: 50px;
		background: grey;
		&.active {
			color: #fff;
			padding-left: 20px; }
		&:first-child {
			padding-right: 20px; } } }

.reveal-shipping {
	text-align: center;
	margin-bottom: 20px;
	dl {
		margin: 0;
		dt {
			font-size: 26px;
			cursor: pointer;
			padding: 15px; }
		dd {
			margin: 0;
			display: none;
			padding: 15px 60px; } } }

.hikawishlist {
	display: none; }

.product-code {
	position: absolute;
	top: 0;
	left: 15px;
	line-height: 87px;
	color: #fff;
	font-weight: bold; }


.btn-cont {
	width: 100%;
	overflow: hidden;
	border-radius: 5px;
	margin-bottom: 5px;
	a,
	.fa,
	button {
		float: left;
		padding: 0;
		margin: 0;
		line-height: 37px;
		background: $scolor;
		font-size: 18px;
		color: #fff;
		border: 0;
		text-align: center;
		@include transition-attr(0.3s, all); }

	.fa {
		width: 10%; }


	button,
	a {
		width: 90%;
		display: block;
		border-radius: 0; }

	&:hover {
		a, .fa, button {
			background: $fcolor;
			color: #fff; } } }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 767px) {
	.hikashop_product_main_image_thumb {
		width: 100% !important;
		padding: 0 !important;
		max-height: 250px !important;
		overflow: hidden !important; }
	.hikashop_product_image_subdiv {
		width: 100% !important;
		padding: 0 !important;
		max-height: 170px !important;
		overflow: hidden !important; } }

.inner-page {
	#main-component {
		padding: 10px 0 100px;
		.container {
			& > div {
				float: right;
				h1 {
					margin-top: 0; }
				a {
					color: #CF4074; }
				p, li {
					margin-bottom: 10px; }
				ul li {
					display: block;
					p, &:before {
						display: inline; }
					&:before {
						content: '\f100';
						font-size: 18px;
						font-family: FontAwesome; } } }
			aside {
				h2 {
					font-size: 24px; } } } }


	&.branches-page #main-component {
		.container {
			background: rgba(255, 255, 255, 0.44); }

		.branches {
			width: 100%;
			margin-bottom: 50px;
			.branch-title {
				margin-bottom: 15px;
				border-bottom: 2px solid $scolor;
				h2 {
					background: $scolor;
					display: inline-block;
					padding: 5px 15px;
					color: #fff;
					border-radius: 10px 10px 0 0; } }




			.branch-container {
				height: 45px;
				img {
					max-width: 40px;
					vertical-align: middle; } } }

		.bottom-part {
			width: 100%;
			padding: 20px 0;
			margin-top: 30px;
			text-align: center;
			border: 1px solid #CF4074; } }


	&.contactus-page {
		#side-icon {
			display: none; }
		#map {
			height: 80vh; }
		#main-component {
			width: 100%;
			padding: 0;
			z-index: 2;
			#myCarousel {
				display: none !important; }
			.bg {
				display: none; }
			.container {
				width: 100%;
				position: relative;

				.col-sm-4 {
					width: 420px;
					position: absolute;
					top: 80px;
					padding: 30px;
					background: $scolor;
					border-radius: 30px;
					&.left {
						left: 30px; }
					&.right {
						right: 30px;
						top: 6px;
						#contactForm {
							margin: 0;
							li {
								width: 100%;
								margin-bottom: 10px;
								position: relative;
								& > * {
									float: right; }
								input, textarea, select {
									width: 100%;
									border-radius: 3px;
									border: 0;
									line-height: 30px;
									background: $fcolor;
									padding: 5px 10px; }

								textarea {
									height: 100px; }

								::-webkit-input-placeholder {
									color: #fff; }

								::-moz-placeholder {
									color: #fff; }

								-ms-input-placeholder: {
									color: #fff; }

								-moz-placeholder: {
									color: #fff; }

								.button {
									float: left;
									background: grey;
									color: #fff;
									font-size: 20px;
									padding: 5px; }
								.plk {
									height: 40px;
									position: absolute;
									top: 0;
									left: 15px;
									border-left: 4px solid $scolor; } } }
						.fox-form {
							margin: 0;
							.fox-item {
								float: none;
								position: relative;
								.controls {
									width: 100%;
									margin-bottom: 10px;
									input, textarea, select {
										width: 100%;
										border-radius: 3px;
										border: 0;
										line-height: 30px;
										background: $fcolor;
										padding: 5px 10px;
										min-height: 40px; }

									textarea {
										height: 100px; }

									::-webkit-input-placeholder {
										color: #fff; }

									::-moz-placeholder {
										color: #fff; }

									-ms-input-placeholder: {
										color: #fff; }

									-moz-placeholder: {
										color: #fff; }

									.btn {
										float: left;
										background: grey;
										color: #fff;
										font-size: 20px;
										padding: 5px;
										margin: 0;
										min-height: 40px;
										width: 100%;
										background-image: none;
										box-shadow: unset;
										border: 0;
										text-shadow: unset; }
									.plk {
										height: 40px;
										position: absolute;
										top: 0;
										left: 15px;
										border-left: 4px solid $scolor; } }
								&.fox-item-radio {
									.controls {
										>div {
											display: flex;
											flex-wrap: wrap;
											justify-content: center;
											gap: 10px;
											.fox-label-inside-no-placeholder {
												display: flex;
												width: 100%;
												flex: 100%;
												font-weight: 700;
												text-decoration: underline;
												margin-bottom: 7px;
												font-size: 17px; }
											.fox-item-radio-label-stacked {
												max-width: 100%;
												width: 100%;
												text-align: center;
												label {
													display: flex;
													flex-wrap: wrap;
													align-items: center;
													font-size: 15px;
													input {
														max-width: 40px; } } }
											input {
												min-height: 10px;
												margin: 0; } } } } } } }
					* {
						color: #fff; }
					h2 {
						color: $fcolor;
						font-size: 26px;
						margin-bottom: 15px; }
					hr {
						border-top: 1px solid #73594f;
						margin: 5px 0; } } } } }


	&.guest-book {
		#main-component .container > div {
			float: none;
			.grid {
				.grid-item {
					float: right;
					padding: 10px;
					.inner-container {
						border: 1px solid #CF4074;
						padding: 20px 20px 0;
						background: rgba(255, 255, 255, 0.5);

						h2 {
							text-align: center;
							color: #CF4074;
							margin: 0 0 20px 0; }
						p {
							text-align: justify; }
						a {
							text-align: center;
							display: block; } }
					.item-image {
						border: 1px solid #CF4074;
						border-top: 0; }

					&.guest-book {
						.inner-container {
							* {
								text-align: center; } }
						h2 {
							display: none; } } } } } }
	&.blog,
	&.wrote-aboutus-page {
		#main-component .container .blog {

			.hr {
				border-top: 6px dotted #c84776;
				margin: 10px 0;
				clear: both;
				float: none; }

			.grid-item {
				margin-bottom: 10px;
				padding: 15px;
				border: 1px solid #CF4074;
				background: #fff;
				.inner-container,
				.item-image {
					float: left; }
				h2 {
					width: 70%;
					margin: 0 0 20px 0;
					padding-left: 15px;
					text-align: right;
					color: #CF4074;
					font-size: 28px;
					position: relative;
					.created-date {
						padding: 5px 10px;
						background: grey;
						float: left;
						color: #fff; } }
				.item-image {
					width: 30%;
					a {
						display: block;
						margin-top: -50px; } }

				.inner-container {
					width: 70%;
					padding-left: 15px;
					position: relative;
					text-align: justify;
					a {
						padding: 10px 25px;
						background: #CF4074;
						color: #fff;
						float: left;
						margin-top: 20px; } } } } }

	&.blog #main-component .item-pageblog .pull-left.item-image {
		max-width: 35%;
		margin-right: 30px; } }

//********************************SearchPage**********************************//
.search-page #main-component {
	min-height: 50vh;
	padding: 30px 0;
	#searchForm {
		.searchintrosearch-page .badge {
			background: $fcolor; }

		.btn-toolbar .btn-group {
			margin: 0;
			.btn {
				background: $scolor;
				color: #fff;
				line-height: 30px;
				border-radius: 10px 0 0 10px;
				&:hover {
					background: $fcolor; } }

			#search-searchword {
				height: 44px;
				padding-right: 10px;
				border: 1px solid $scolor;
				border-radius: 0 10px 10px 0; } } }

	.search-results {
		.result-text {
			margin-bottom: 20px; } } }
//-----------------------------End of SearchPage------------------------------//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@media screen and (max-width: 1400px) {
	.inner-page.contactus-page #main-component {
		background: $scolor;
		.container {
			.left,
			.right {
				width: 50%;
				position: relative;
				top: 0 !important;
				left: auto !important;
				right: auto !important; } } } }

@media screen and (max-width: 1200px) {
	.inner-page {
		&.wrote-aboutus-page #main-component .container .blog .grid-item {
			h2 {
				width: 100%;
				padding: 0; }
			.item-image a {
				margin-top: 0; } } } }


@media screen and (max-width: 850px) {
	.inner-page {
		&.contactus-page {
			#main-component {
				margin-top: 20px;
				background: $scolor;
				.container {
					.left,
					.right {
						width: 100%;
						padding: 10px 30px; } } }
			#map {
				height: 280px; } }

		&.branches-page #main-component * {
			font-size: 16px; } } }

@media screen and (max-width: 767px) {
	.inner-page {
		&.wrote-aboutus-page #main-component .container .blog .grid-item {
			.item-image,
			.inner-container {
				width: 100%; }
			h2 {
				padding: 0 0 35px 0;
				text-align: center;
				.created-date {
					position: absolute;
					bottom: 0;
					left: 0;
					font-size: 16px; } }
			.inner-container * {
				text-align: justify; }
			.item-image {
				text-align: center;
				img {
					width: 300px; } } } } }

@media screen and (max-width: 500px) {
	.inner-page.contactus-page	#main-component .container {
		.left,
		.right {
			padding: 10px 0; } } }

.login,
.registration {
	#main-component {
		.login,
		.registration {
			max-width: 400px;
			margin: 60px auto 0;
			form {
				background: #554c44;
				border-radius: 20px;
				.control-group {
					margin-bottom: 15px;
					&.jform_spacer .control-label {
						text-align: left; }

					.control-label {
						margin: 0;
						padding: 0;
						vertical-align: middle;
						label {
							color: #fff;
							font-weight: 100;
							span.star {
								float: right;
								margin: 1px 0 0 4px;
								color: #d3386b; } } }


					.controls {
						vertical-align: middle;
						* {
							width: 100%;
							height: 40px;
							border: 0;
							line-height: 40px;
							padding: 0 10px;
							border-radius: 10px;
							font-size: 20px; }

						.btn-primary,
						a {
							margin-top: 20px;
							color: #fff;
							background-color: #d3386b;
							border-bottom: 1px solid #fff;
							border-top: 1px solid #fff;
							border-radius: 20px; } }
					&.remember {
						* {
							display: inline-block;
							&.controls input {
								width: 15px;
								height: 15px; }
							&.control-label label {
								margin: 0; } } }
					&.facebook {
						a {
							display: block;
							width: 100%;
							.fa {
								width: 35px; } } } } } }

		.nav.nav-stacked {
			max-width: 400px;
			margin: 0 auto;
			border: 0;
			li>a {
				margin: 9px 0;
				line-height: 24px;
				border: 0;
				background: #eac9d4;
				text-align: center;
				border-bottom: 2px solid #554c44;
				border-top: 2px solid #554c44;
				border-radius: 20px;
				&:hover {
					background-color: #d3386b;
					color: #fff; } } } } }

.account #main-component {
	min-height: 45vh;
	.container {
		background: rgba(255, 255, 255, 0.42);
		.hikashop_cpanel .hk-row-fluid {
			text-align: center;
			.icon-wrapper {
				display: inline-block;
				.icon a {
					border: 0px;
					background: none;
					span {
						margin-top: 15px;
						color: $scolor;
						font-weight: bold; } } } }
		.fa {
			background: $fcolor;
			font-size: 26px;
			width: 50px;
			height: 50px;
			line-height: 50px;
			color: #fff;
			border-radius: 50%;
			text-align: center; }
		table {
			border-radius: 15px;
			overflow: hidden;
			th {
				border-top: 0;
				background: $scolor;
				color: #fff;
				white-space: nowrap;
				&,
				& * {
					font-size: 16px;
					text-shadow: none;
					font-weight: 100;
					text-align: center; } } }

		.hikashop_search_block {
			height: 40px;
			margin: 25px 0;

			* {
				border: 0;
				float: right;
				height: 40px;
				border-radius: 0;
				color: #fff; }

			#hikashop_search {
				background: #808080;
				padding: 0 10px;
				border-radius: 0 10px 10px 0;
				&::-webkit-input-placeholder {
					color: $scolor; }

				&::-moz-placeholder {
					color: $scolor; }

				&:-ms-input-placeholder {
					color: $scolor; }

				&:-moz-placeholder {
					color: $scolor; } }


			.btn {
				background: $scolor;
				border-right: 2px solid $fcolor;
				&:hover {
					background: $fcolor; }
				&:last-child {
					border-radius: 10px 0 0 10px; } } }



		.row.blog-title .fa {
			background: none; }

		.toolbar.hikashop_header_buttons .fa {
			display: block; } } }

.page-id-162 #main-component {
	.container {} }



.registration #main-component .registration {
				margin: 30px auto 0; }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 600px) {
	.login,
	.registration {
		#side-icon {
			display: none; } } }

.page-id-133 {
	.breadcrumb {
		padding: 0;
		background: $fcolor;
		margin: 20px 0;
		border-radius: 35px;
		overflow: hidden;
		* {
			display: inline-block;
			background: none;
			margin: 0 !important;
			color: #fff;
			height: 50px;
			float: right;
			padding: 0 3px;
			line-height: 50px; } }

	#side-icon {
		display: none; }

	#main-component {
		min-height: 70vh;
		padding: 20px 0 80px;
		.container {
			background: rgba(255, 255, 255, 0.39);

			input, textarea, select {
				width: 100%;
				@include inputDefault(); }
			label {
				font-size: 16px; }



			p {
				font-size: 16px;
				font-weight: 100  !important; }
			.col-sm-4 {
				padding: 0; }
			#hikashop_checkout {
				background: #fff;
				h1,
				h2 {
					margin-bottom: 10px;
					border-bottom: 1px solid $scolor;
					span {
						font-weight: 600; }
					.step-badge {
						width: 30px;
						height: 30px;
						background: $fcolor;
						border-radius: 50% 50% 0 0;
						display: inline-block;
						text-align: center;
						line-height: 30px;
						color: #fff;
						font-size: 16px; } }
				.block-sub-title {
					margin-bottom: 10px;
					font-weight: 600; }

				&.hikashop_checkout_page_step2 {
					a.hikabtn.hikabtn_checkout_guest_logout {
						display: none; }


					#pitputim-login-selector {
						margin: 20px 0 30px;
						text-align: center;
						border-bottom: 1px solid $fcolor;
						ul {
							display: inline-block;
							text-align: center;
							li {
								display: inline-block;
								padding: 3px 10px;
								background: $scolor;
								color: #fff;
								float: right;
								cursor: pointer;
								@include transition-attr(all, .3s);
								&:first-child {
									border-radius: 0 10px 0 0; }
								&:nth-child(2) {
									border-left: 1px solid #fff;
									border-right: 1px solid #fff; }
								&:last-child {
									border-radius: 10px 0 0 0; }
								&.active,
								&:hover {
									background: $fcolor; } } } }

					.pitputim-login {
						max-width: 450px;
						margin: 0 auto;
						float: none;
						padding-top: 6px;
						border-top: 2px solid $scolor; } }


				.hkc-lg-1,
				.hkc-lg-4 {
					float: right;
					.hkform-group {
						margin-left: 0;
						margin-right: 0;
						position: relative;
						.fa-asterisk {
							position: absolute;
							top: 6px;
							left: 10px;
							z-index: 1;
							color: $scolor;
							font-size: 10px; } }
					#hikashop_checkout_registration {
						.hkc-lg-12 {
							float: none;
							clear: both; }
						.hikashop_registration_required_info_line span {
							font-size: 16px; } }
					#hikashop_checkout_login_form {
						.hikashop_login_remember_line {
							height: 25px;
							input {
								width: 22px;
								height: 22px;
								float: right;
								margin: 0; }
							label {
								margin: 0 5px 0 0; } }


						.forg,
						.hikashop_login_button_line {
							margin: 0;
							clear: both; }

						.hikashop_login_button_line {
							margin-top: 20px; }
						.forg {
							clear: both; } } }

				.hikashop_checkout_address {
					h2 {
						margin: 0; }
					input[type='radio'] {
						width: 20px;
						margin-top: 14px;
						float: right; }
					legend {
						width: auto;
						margin-right: -10px;
						padding: 0 10px;
						display: inline-block;
						border-radius: 0 0 10px 10px;
						background: $scolor;
						color: #fff;
						font-size: 18px; }

					button {
						background: $scolor;
						width: 100%;
						color: #fff;
						border: 0;
						border-radius: 0 0 10px 10px;
						border-top: 2px solid $fcolor;
						font-size: 14px; } }

				#hikashop_address_1_0_address_title {
					display: none; }

				.hikashop_checkout_cart {
					float: none;
					clear: both;
					table {
						width: 100%;
						border: 0;

						* {
							text-align: right;
							font-size: 16px; }
						thead tr {
							background: $scolor;
							th {
								padding: 3px 5px;
								color: #fff;
								font-weight: 100;
								font-size: 16px; } }
						tbody tr {
							border-bottom: 1px solid #fff;
							&:nth-child(2n) {
								background: #f9e5ec; }
							&:nth-child(2n+1) {
								background: #fdd8f3; }
							td {
								padding: 2px 5px;
								&.hikashop_cart_product_image_value * {
									text-align: center; }
								&.hikashop_cart_product_quantity_value {
									.hikashop_product_quantity_field {
										width: 50px;
										padding: 0;
										background: $scolor;
										border-radius: 10px;
										text-align: center;
										color: #fff; }
									.hikashop_product_quantity_field,
									.hikashop_cart_product_quantity_refresh,
									.hikashop_cart_product_quantity_delete {
										display: inline-block; } }
								&.hikashop_cart_product_image_value img {
									max-width: 90px;
									max-height: 90px;
									border: 2px solid $scolor; } } }
						tfoot tr {
							&:first-child td {
								border-top: 2px solid $scolor; }
							td {
								padding: 3px 5px;
								border: 0;
								&:not(.hikashop_cart_empty_footer) {
									background: $scolor;
									color: #fff;
									border-bottom: 1px solid #fff; }
								&.hikashop_cart_title {
									padding-left: 20px;
									text-align: left; }
								&.hikashop_cart_total_title.hikashop_cart_title {
									background: $fcolor;
									border-radius: 0 0 15px 0;
									color: #fff;
									font-size: 20px; }

								&.hikashop_cart_total_value {
									background: $fcolor;
									border-radius: 0 0 0 15px;
									span {
										color: #fff;
										font-size: 20px; } } } } } }
				.hikashop_checkout_coupon {
					input {
						margin: 0; }
					.btn-cont {
						width: 30%;
						border-radius: 0 0 10px 10px;
						float: left;
						button {
							width: 100%;
							font-size: 16px; } } }
				.container-shipping {
					margin-top: 20px;
					padding-right: 0; }

				.container-payment {
					margin-top: 20px;
					padding-left: 0; }

				.container-fields {
					margin-bottom: 30px;
					padding-right: 0;
					clear: both; }


				.container-coupon {
					padding-right: 0; }


				.hikashop_checkout_payment,
				.hikashop_checkout_shipping {

					* {
						border: 0;
						background: transparent;
						vertical-align: middle; }
					img {
						width: 25px; }
					input,
					label {
						margin: 0;
						line-height: 15px;
						vertical-align: middle; }
					input {
						width: 15px;
						height: 15px; }
					label span {
						height: 15px;
						line-height: 20px; }
					span {
						font-size: 14px; } }

				#hikashop_order_3_1_delivery_date {
					width: 50%;
					display: inline-block;
					vertical-align: top;
					.input-append {
						position: relative;
						#delivery_date_btn {
							position: absolute;
							left: 0;
							top: 0;
							width: 80px;
							height: 31px;
							background: $scolor;
							border-radius: 0 10px 10px 0;
							&:before {
								display: block;
								content: "\f073";
								margin-top: -2px;
								font-family: FontAwesome;
								color: #fff;
								line-height: 18px;
								font-size: 18px; }

							&:hover {
								background: $fcolor; } } }
					.js-calendar .calendar-container {
						border-radius: 0 0 5px 5px;
						background-color: $scolor !important;
						color: #fff;
						border: 2px solid $fcolor;
						.table {
							border-radius: 0;

							.today::after {
								background-color: #cf4074; }

							.selected {
								background: $fcolor; } }

						.buttons-wrapper.btn-group {
							width: 100%;
							text-align: center;
							a {
								float: none; } } } }

				.hikashop_fields_button_line {
					display: inline-block;
					width: 50%;
					vertical-align: top;
					.hkc-sm-4 {
						display: none; }
					.hkc-sm-8 {
						width: 100%;
						button {
							width: 100%;
							display: block;
							color: #fff;
							background-color: #cf4074;
							font-size: 20px;
							padding: 1px;
							border: 0px;
							border-radius: 0px; } } }

				#hikashop_checkout_fields_3_6 {
					float: none;
					clear: both;
					padding: 0;
					.hikashop_checkout_terms {
						text-align: left; } }


				.hikashop_checkout_fields {
					padding: 20px 0;
					.hikashop_checkout_delivery_date_line {
						.hkradio-inline {
							padding: 0 20px 0 0;
							margin-left: 10px;
							input {
								width: 20px;
								margin: 4px -20px 0 4px; } } } }

				.hikashop_checkout_status {
					clear: both;
					text-align: right;
					direction: rtl;
					.label.label-info {
						background: $fcolor; } }

				.hikashop_checkout_fields .hikashop_checkout_terms {
					clear: both;
					.hikashop_checkout_terms_checkbox,
					.hikashop_checkout_terms_checkbox_1_7 {
						display: inline-block; }

					.hikashop_checkout_terms_checkbox {
						width: 24px !important; } }

				.hikashop_checkout_buttons {
					.buttons_left.btn-cont,
					.buttons_right.btn-cont {
						width: 250px; }
					.buttons_left.btn-cont {
						float: right; }

					.buttons_right.btn-cont {
						float: left; } } } } } }

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 992px) {
	.page-id-133 #main-component {
		padding: 20px 0;
		.container {
			#hikashop_checkout {
				.col-sm-4,
				.hikashop_checkout_login .hkc-lg-4 {
					width: 100%; }

				.hikashop_checkout_cart {
					width: 100%;
					display: inline-block;
					table tfoot tr td {
						&.hikashop_cart_total_title.hikashop_cart_title,
						&.hikashop_cart_total_value,
						&.hikashop_cart_shipping_title,
						&.hikashop_cart_shipping_value,
						&.hikashop_cart_subtotal_value,
						&.hikashop_cart_subtotal_title {
							width: 50%;
							float: right; }
						&:before {
							display: none; } } }
				.hikashop_checkout_buttons .buttons_right.btn-cont button {
					height: 80px;
					background: $fcolor;
					margin-top: 15px;
					border-radius: 10px; } } } } }


@media screen and (max-width: 767px) {

	.page-id-133 #main-component .container #hikashop_checkout {
		.hikashop_checkout_address .hk-container-fluid .hkc-sm-6 {
			margin-bottom: 15px; }

		.hikashop_checkout_cart,
		.container-shipping,
		.container-payment,
		.container-coupon {
			width: 100%;
			padding: 0;
			margin-bottom: 15px; }

		#hikashop_checkout_fields_3_6 .hikashop_checkout_terms {
			text-align: right; }

		#hikashop_order_3_1_delivery_date {
			width: 100%; }

		.hikashop_checkout_buttons {
			width: 100%;
			.buttons_right.btn-cont button {
				height: 50px;
				width: 100%; } } }

	.hikashop_checkout_cart table tfoot tr td:not(.hikashop_cart_empty_footer) {
		padding-left: 10px !important; } }

